<template>
  <b-card
    no-body
    :class="[
      {
        'pdf-export': renderedForPDF,
        'small-font-on-print-offering': renderedForPDF
      },
      'overflow-hidden std-border offering-card p-3 mb-0 my-2'
    ]"
    body-class="p-1"
  >
    <b-row>
      <b-col :cols="12">
        <h5 class="mb-0">{{ offering.main_section }}</h5>
        <p class="mb-2">{{ offering.category_name }}</p>
      </b-col>
    </b-row>
    <b-row>
      <b-col :cols="1"></b-col>
      <b-col :cols="6" class="pl-0">
        <p class="mb-2"><b>Service</b></p>
      </b-col>
      <b-col :cols="4" class="pl-0">
        <span class="mb-2"><b>Peer Group %</b></span>
      </b-col>
    </b-row>
    <b-row>
      <b-col :cols="12">
        <div v-for="(category, index) in offering.children" :key="index">
          <b-row v-if="category.children.length">
            <b-col :cols="12" class="pt-1">
              <b-row>
                <b-col :cols="1" class="pr-0"> </b-col>
                <b-col :cols="6" class=" pl-0 pr-0">
                  <b> {{ category.name }} </b>
                </b-col>
                <b-col :cols="1"></b-col>
                <b-col :cols="4" class="pl-0">
                  {{ category.peer_group_value }}
                </b-col>
              </b-row>
              <b-row
                v-for="(subCategory, index) in category.children"
                :key="index"
              >
                <b-col :cols="1" class=" pr-0">
                  <div
                    :class="[
                      isMobile
                        ? subCategory.has_service
                          ? 'has-service-mob'
                          : 'has-no-service-mob'
                        : subCategory.has_service
                        ? 'has-service'
                        : 'has-no-service'
                    ]"
                  ></div>
                </b-col>
                <b-col :cols="6" class=" pl-0 pr-1">
                  <div class="service-name" :title="subCategory.name">
                    {{ subCategory.name }}
                  </div>
                </b-col>

                <b-col
                  :cols="3"
                  class="align-self-center cursor-pointer pl-0"
                  :title="`${subCategory.peer_group_value}%`"
                >
                  <b-progress
                    :value="subCategory.peer_group_value"
                    :max="100"
                  ></b-progress>
                </b-col>
                <b-col :cols="2" class=" pl-0 pr-2 text-right">
                  <div class="service-name">
                    {{ subCategory.peer_group_value + '%' }}
                  </div>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row v-else>
            <b-col :cols="1" class=" pr-0">
              <div
                :class="[
                  isMobile
                    ? category.has_service
                      ? 'has-service-mob'
                      : 'has-no-service-mob'
                    : category.has_service
                    ? 'has-service'
                    : 'has-no-service'
                ]"
              ></div>
            </b-col>
            <b-col :cols="6" class=" pl-0 pr-1">
              <div class="service-name" :title="category.name">
                {{ category.name }}
              </div>
            </b-col>

            <b-col
              :cols="3"
              class="align-self-center cursor-pointer pl-0"
              :title="`${category.peer_group_value}%`"
            >
              <b-progress
                :value="category.peer_group_value"
                :max="100"
              ></b-progress>
            </b-col>
            <b-col :cols="2" class=" pl-0 pr-2 text-right">
              <div class="service-name">
                {{ category.peer_group_value + '%' }}
              </div>
            </b-col>
          </b-row>
        </div>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'OfferingCard',
  props: {
    offering: {
      type: Object,
      required: true,
      default() {
        return {}
      }
    },
    renderedForPDF: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  created() {},
  computed: {
    ...mapState('Profile', {
      isMobile: state => state.isMobile
    })
  }
}
</script>

<style lang="scss" scoped>
.offering-card {
  background-color: #20a8d8;
  color: white;

  .has-service {
    display: inline-block;
    width: 0.625rem;
    height: 0.625rem;
    border-radius: 50%;
    background-color: white;
  }

  .has-no-service {
    display: inline-block;
    width: 0.625rem;
    height: 0.625rem;
    border-radius: 50%;
    border: solid 1px white;
  }
  .has-service-mob {
    display: inline-block;
    width: 0.4rem;
    height: 0.4rem;
    border-radius: 50%;
    background-color: white;
  }

  .has-no-service-mob {
    display: inline-block;
    width: 0.4rem;
    height: 0.4rem;
    border-radius: 50%;
    border: solid 1px white;
  }
  .service-name {
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
    white-space: nowrap;
  }

  &.pdf-export {
    background-color: #fff;
    color: #0a80aa;

    .has-service {
      background-color: #0a80aa;
    }

    &.std-border {
      border-color: #0a80aa;
    }
  }
}
</style>

<style lang="scss">
.offering-card {
  .progress {
    background-color: #20a8d8;
    .progress-bar {
      background-color: white;
      color: black;
    }
  }

  &.pdf-export {
    .progress {
      background-color: #fff;
      color: black;
      .progress-bar {
        background-color: #0a80aa;
      }
    }
  }
}
</style>
