<template>
  <PageCardWithInstitution
    pageName="FIN Profile"
    :logoUrl="s3LogoUrl"
    :institutionId="selectedInstitution.id"
    :institutionName="selectedInstitution.name || ''"
    :iconClass="['cuis-dashboard', 'card-icon-font']"
    :pageClasses="['page-card', 'fin-profile']"
    :cardBodyClasses="['custom-profile']"
  >
    <template slot="page_content">
      <b-row class="custom-profile-tabs">
        <b-col sm="12">
          <v-wait for="loadingSectionData">
            <template slot="waiting">
              <ReportPlaceholder
                :progress="reportProgress || 0"
              ></ReportPlaceholder>
            </template>
            <b-card class="profile-card" body-class="px-2 py-0">
              <div class="text-right pb-2 profile-icon-tray">
                <v-wait for="preparingPrint" v-if="!isMobile">
                  <template slot="waiting">
                    <i class="fa fa-lg px-2 fa-spinner fa-spin"></i>
                  </template>
                  <i
                    @click="printProfile"
                    title="Print Profile"
                    class="cuis-print card-icon-font font-2xl px-1 xlsx-link cursor-pointer"
                  ></i>
                </v-wait>
                <v-wait for="downloadingReport" v-if="!isMobile">
                  <template slot="waiting">
                    <i class="fa fa-lg px-2 fa-spinner fa-spin"></i>
                  </template>
                  <i
                    class="cuis-file-excel card-icon-font font-2xl pr-2 cursor-pointer xlsx-link"
                    title="Download xlsx"
                    @click="downloadXlsx"
                  ></i>
                </v-wait>
                <i
                  v-b-modal="'userSectionModal'"
                  class="fa fa-lg fa-bars cursor-pointer"
                  aria-hidden="true"
                  v-if="!isMobile"
                ></i>
              </div>
              <div role="tablist">
                <b-card
                  no-body
                  v-for="(section, index) in enabledSections"
                  :class="[
                    isSelectedSection(section) ? 'active-card' : '',
                    'mb-1',
                    'std-border'
                  ]"
                  v-bind:key="index"
                >
                  <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-btn block href="#" v-b-toggle="'accordion_' + index">
                      <b-row>
                        <b-col cols="9">
                          <div class="d-flex align-items-center">
                            <div>
                              <span
                                :class="[
                                  'pr-1',
                                  'card-icon-font',
                                  sectionIconMapper[section.name]
                                ]"
                              ></span>
                            </div>
                            <div>
                              <h6 class="mb-0">
                                <b>{{ sectionTitle(section.name) }}</b>
                              </h6>
                            </div>
                          </div>
                        </b-col>

                        <b-col cols="3" class="text-right">
                          <span class="pr-2">
                            <i
                              class="icon-info icons small-line-icon  cursor-pointer"
                              v-if="showMoreInfo(section.name)"
                              v-on:click.stop.prevent="
                                $refs[
                                  `info-${componentName(section.name)}`
                                ][0].show()
                              "
                            ></i
                          ></span>
                          <span
                            v-b-tooltip.hover
                            title="Submit a Service Update"
                            class="fa fa-pencil-square-o  pr-2 cursor-pointer"
                            v-if="showBDIEdit(section) && !isMobile"
                            v-b-modal.bankServiceVPModal
                          ></span>

                          <i
                            class="fa fa-lg fa-cog"
                            v-on:click.stop.prevent="manageSection(section)"
                            aria-hidden="true"
                            v-if="section.show_config && !isMobile"
                            v-html="'&nbsp;&nbsp;'"
                          ></i>
                          <i
                            class="fa fa-lg fa-caret-down"
                            aria-hidden="true"
                          ></i>
                        </b-col>
                      </b-row>
                    </b-btn>
                  </b-card-header>
                  <b-modal
                    size="lg"
                    :ref="`info-${componentName(section.name)}`"
                    class="info-modal"
                    :title="section.name"
                    ok-only
                  >
                    <div>
                      <span
                        v-html="getMoreInfo(componentName(section.name))"
                      ></span>
                    </div>
                  </b-modal>
                  <b-collapse
                    accordion="my-accordion"
                    role="tabpanel"
                    v-bind:id="'accordion_' + index"
                    @shown="setSelectedSection(section)"
                    :visible="isSelectedSection(section)"
                  >
                    <b-card-body>
                      <keep-alive>
                        <component
                          v-bind:is="componentName(section.name)"
                          :sectionInfo="section"
                          ref="profileSection"
                        ></component>
                      </keep-alive>
                    </b-card-body>
                  </b-collapse>
                </b-card>
              </div>
            </b-card>
          </v-wait>
        </b-col>
        <UserSectionSelectionModal
          :sections="userSections"
        ></UserSectionSelectionModal>
        <SectionConfigModal
          :showModal="showSectionConfigModal"
          :section="selectedConfigSection"
          @configUpdated="configUpdated"
          @closeModal="closeModal"
        ></SectionConfigModal>
        <bank-service-v-p-modal
          :financialInstituion="selectedInstitution"
          @bdiDataUpdated="bdiDataUpdated"
        ></bank-service-v-p-modal>
        <SubStructureModal :selectedInstitution="selectedInstitution">
        </SubStructureModal>
      </b-row>
      <div class="custom-profile-pdf">
        <div class="report-header pb-3">
          <div class="row">
            <div class="col-sm-6">
              <img src="/logo.png" class="report-logo" />
            </div>
            <div class="col-sm-6 text-right align-self-end">
              <div class=" d-flex justify-content-end">
                <header-logo
                  logoType="fi"
                  :logoUrl="s3LogoUrl"
                  :logoId="selectedInstitution.id"
                ></header-logo>

                <h5 class="pt-1 text-right ">{{ selectedInstitution.name }}</h5>
              </div>
            </div>
          </div>
        </div>
        <b-card
          no-body
          :class="['mb-1', 'std-border', 'profile-section']"
          v-for="(section, index) in pdfEnabledSections"
          v-bind:key="index"
        >
          <template slot="header">
            <b-row>
              <b-col cols="12">
                <div class="d-flex align-items-center">
                  <div>
                    <span
                      :class="[
                        'pr-1',
                        'card-icon-font',
                        sectionIconMapper[section.name]
                      ]"
                    ></span>
                  </div>
                  <div>
                    <h6 class="mb-0">
                      <b>{{ sectionTitle(section.name) }}</b>
                    </h6>
                  </div>
                </div>
              </b-col>
            </b-row>
          </template>
          <b-card-body>
            <component
              v-bind:is="componentName(section.name)"
              :sectionInfo="section"
              :renderedForPDF="true"
              ref="profileSection"
            ></component>
          </b-card-body>
        </b-card>
        <div class="d-none empty-print-only"></div>
      </div>
    </template>
  </PageCardWithInstitution>
</template>

<script>
import Vue from 'vue'
import store from '@/store/store'
import router from '@/router'
import { mapState, mapGetters, mapActions } from 'vuex'
import PageCardWithInstitution from '@/modules/core/components/layouts/PageCardWithInstitution'
import UserSectionSelectionModal from '../components/UserSectionSelectionModal'
import SectionConfigModal from '../components/SectionConfigModal'
import ReportPlaceholder from '../components/ReportGeneratorPlaceholder'
import downloadsAPI from '@/modules/core/api/downloads'

import BankProfile from '../components/BankProfile'
import Profit from '../components/Profit'
import Growth from '../components/Growth'
import Market from '../components/Market'
import Risk from '../components/Risk'
import SegmentationInsights from '../components/SegmentationInsights'
import Strategy from '../components/Strategy'
import TechnographicVendors from '../components/Technographics'
import Offering from '../components/Offering'
import MergerHistory from '../components/MergerHistory'
import DigitalChannels from '../components/DigitalChannels'
import FIContacts from '../components/FIContacts'
import BankServiceVPModal from '@/modules/online_edits/components/BankServiceVPModal.vue'
import SubStructureModal from '@/modules/advisor/components/SubStructureModal'
import HeaderLogo from '@/modules/core/components/HeaderLogo'
export default {
  name: 'profileReport',
  components: {
    PageCardWithInstitution,
    BankProfile,
    Offering,
    Profit,
    Growth,
    Market,
    Risk,
    SegmentationInsights,
    Strategy,
    TechnographicVendors,
    UserSectionSelectionModal,
    SectionConfigModal,
    ReportPlaceholder,
    MergerHistory,
    DigitalChannels,
    FIContacts,
    BankServiceVPModal,
    SubStructureModal,
    HeaderLogo
  },
  beforeRouteEnter(to, from, next) {
    store.commit('Profile/setIsMobile', screen.width <= 760 ? true : false)
    if (store.getters['Profile/selectedInstitution'].id) {
      next()
    } else {
      Vue.toasted.show('Please select institution.', {
        icon: 'chain-broken',
        type: 'error'
      })
      router.push('/analytics/fin_profile/select_institution')
    }
  },
  beforeCreate() {
    store.commit('Profile/resetProfileData')
  },
  created() {
    if (!this.partnerSite) {
      document.title = 'FIN Profile'
    }
    this.$wait.start('loadingSectionData')
    Promise.all([
      this.fetchReportProgress(),
      // this.loadEnterprisePermissions(),
      this.fetchUserSections()
    ]).then(() => {
      this.updateDisplayNameInSections()
      this.fetchSectionConfigs().then(() => {
        this.setSelectedSection(this.enabledSections[0])
        this.$wait.end('loadingSectionData')

        let event = this.isDirectLogin
          ? 'report_view - Direct URL'
          : 'report_view'
        if (this.reportId != '') {
          this.$ahoy.track(event, {
            fi_id: this.selectedInstitution.id,
            report_id: this.reportId,
            report_type: 'FIN-P'
          })
        } else {
          this.$ahoy.track(event, {
            fi_id: this.selectedInstitution.id,
            report_type: 'FIN-P'
          })
        }
      })
    })
  },
  data() {
    return {
      selectedSection: {},
      selectedConfigSection: {},
      showSectionConfigModal: false,
      moreInfo: {
        Offering: `<p>To assist in the assessment of financial institution customer offering, FI Navigator has developed the industry’s most extensive solution hierarchy. Harnessing big data in an innovative way, FI Navigator analyzes every financial institutions’ primary website and other data sources monthly to identify whether the institution offers the solution and, when applicable, the vendor-product utilized.</p><p>
        Principally focusing on retail and business solutions, the hierarchy covers nearly 300 solutions ranging from channels to payments to lending products. The hierarchy is evolving rapidly with additions monthly to allow executives to stay abreast of continual innovations in banking’s digital transformation. We expect this solution roadmap to continue to evolve rapidly and encourage suggestions on individual solutions or categories.</p>`,

        'Strategy-bank': `<p>FI Navigator has developed a strategic classification system to ensure the matching of a strategic peer group that shares a similar lending, funding and off-balance sheet focus. A financial institution’s operating strategy, as reflected by its balance sheet and revenue composition heavily influences its route towards high performance. Comparison to strategic comparables generates performance exceptions (or conclusions) that, when addressed, are much more likely to impact performance.</p>
<p>The base strategic classification divides financial institutions into five (5) lending strategies, three(3) funding strategies and three (3) off-balance sheet revenue levels. These combinations result in 45 strategic classes. An additional two (2) classifications cover the atypical strategies of credit card institutions and bankers’ banks.</p>
<img src='https://apps.finavigator.com/img/bank-strategy.d69d5111.png' style =" max-width:100%; height: auto;">`,

        'Strategy-credit_union': `<p>FI Navigator has developed a strategic classification system to ensure the matching of a strategic peer group that shares a similar lending, funding and off- balance sheet focus. A credit union’s operating strategy, as reflected by its balance sheet and revenue composition heavily influences its route towards higher performance. Comparison to strategic comparables generates performance exceptions (or conclusions) that, when addressed, are much more likely to accurately guide resource allocation for performance improvement.</p>
<p>The base strategic classification divides financial institutions into four (4) lending strategies, three (3) funding strategies and two (2) off-balance sheet revenue levels. These combinations result in 24 strategic classes.</p>
<p>The four lending strategies (and their abbreviated labels) are secured personal lending (AUTO), residential real estate lending (HOME), unsecured personal lending (UPER) and diverse lending focus (DIVR). The AUTO loan focus group is for institutions where secured personal lending exceeds the 60th percentile and exceeds both residential real estate and unsecured personal lending for the institution. The HOME loan focus group is for institutions where residential real estate lending exceeds the 75th percentile and exceeds both secured personal and unsecured personal lending for the institution. The UPER loan focus group is for institutions where unsecured lending exceeds the 66th percentile and exceeds both secured personal and residential real estate lending for the institution. The DIVR loan focus group is for credit unions with more diverse portfolios that would not meet the thresholds for the other three groups.</p>
<p>The three funding strategies (and their abbreviated labels) are low share balance (LBAL), moderate share balance (MBAL) and high share balance (HBAL). The classifications utilize the institutions average share balance with those under the 33rd percentile in the low balance (LBAL) group and those between the 33rd and 60th percentile in the moderate balance (MBAL) group. The high balance (HBAL) group’s average balances exceeds the 60th percentile. Lower share balances are highly correlated with lower cost of deposits and higher fee income levels – but are also associated with higher personnel and other expenses.</p>
<p>The two off-balance sheet classifications are moderate (MOD) and high (HGH). The classification examines the credit union’s level of other operating income relative to average earning assets. Other operating income for the institution includes real estate loan secondary market income, interchange income, and other recurring non-service charge income. As such the metric is a proxy for expanded and/or off-balance sheet income sources. Institutions below the 40th percentile in other operating income to average earning assets are designated as moderate (MOD) with the remainder designated as high (HGH).</p>
<img src='https://apps.finavigator.com/img/cu-strategy.090ffb39.png' style =" max-width:100%; height: auto;">

`,

        TechnographicVendors: `<p>To assist in the assessment of financial institution customer offering, FI Navigator has developed the industry’s most extensive solution hierarchy. Harnessing big data in an innovative way, FI Navigator analyzes every financial institutions’ primary website and other data sources monthly to identify whether the institution offers the solution and, when applicable, the vendor-product utilized.</p>
        <p>Principally focusing on retail and business solutions, the hierarchy covers nearly 300 solutions ranging from channels to payments to lending products. The hierarchy is evolving rapidly with additions monthly to allow executives to stay abreast of continual innovations in banking’s digital transformation. We expect this solution roadmap to continue to evolve rapidly and encourage suggestions on individual solutions or categories.</p>
         <p>In addition to retail and business solutions coverage, FI Navigator has extended focus to operating, non-customer facing solutions such as core processing. </p>
         <p>While the Offering section focuses what solutions the financial institution is taking to market, the Technographic Vendors section reports the technology stack deployed to deliver the products, services, or channels. </p>`
      }
    }
  },
  computed: {
    ...mapGetters('Authentication', ['isDirectLogin']),
    ...mapGetters('Profile', [
      'selectedInstitution',
      's3LogoUrl',
      'reportId',
      'reportProgress',
      'isCreditUnionSelected'
    ]),
    ...mapState('Profile', {
      userSections: state => state.userSections,
      isBankProfileLoaded: state => state.isBankProfileLoaded,
      isProfitLoaded: state => state.isProfitLoaded,
      isGrowthLoaded: state => state.isGrowthLoaded,
      isRiskLoaded: state => state.isRiskLoaded,
      isOfferingLoaded: state => state.isOfferingLoaded,
      isStrategyLoaded: state => state.isStrategyLoaded,
      isMarketLoaded: state => state.isMarketLoaded,
      isTechnographicVendorsLoaded: state => state.isTechnographicVendorsLoaded,
      isMergerHistoryLoaded: state => state.isMergerHistoryLoaded,
      marketShareFIType: state => state.marketShareFIType,
      isMobile: state => state.isMobile
    }),
    ...mapState({
      partnerSite: state => state.partnerSite
    }),
    enabledSections() {
      return this.userSections.filter(section => section.status === true)
    },
    pdfEnabledSections() {
      //Contacts has a separate print/download option - So, not adding here
      if (this.selectedInstitution.fiType === 'bhc') {
        return this.enabledSections.filter(
          section => section.name !== 'Market' && section.name !== 'FI Contacts'
        )
      } else {
        return this.enabledSections.filter(
          section => section.name !== 'FI Contacts'
        )
      }
    },
    sectionIconMapper() {
      return {
        'Bank Profile': 'cuis-home',
        Profit: 'cuis-percent',
        Growth: 'cuis-graph',
        Risk: 'cuis-balance-scale',
        Offering: 'cuis-handshake',
        Strategy: 'cuis-map',
        Market: 'cuis-location-pin',
        'Technographic Vendors': 'cuis-screen-desktop',
        'Segmentation & Insights': 'cuis-puzzle-piece',
        'Merger History': 'cuis-history',
        'Digital Channels': 'icon-screen-smartphone',
        'FI Contacts': 'cui-address-book'
      }
    }
  },
  watch: {
    reportProgress: function(progress) {
      this.showSectionConfigModal = false
      if (progress === 100) {
        this.$wait.end('loadingSectionData')
        this.$nextTick(function() {
          this.configUpdated()
        })
      } else {
        this.$wait.start('loadingSectionData')
      }
    }
  },
  methods: {
    ...mapActions('Profile', [
      'fetchReportProgress',
      'fetchUserSections',
      'fetchSectionConfigs',
      'fetchAllProfileDetails'
    ]),
    isSelectedSection(section) {
      if (!this.selectedSection) {
        return false
      }
      return this.selectedSection === section
    },
    // ...mapActions('AdvancedQuery', ['loadEnterprisePermissions']),
    getMoreInfo(sectionName) {
      if (sectionName === 'Strategy')
        return this.selectedInstitution.fiType === 'credit_union'
          ? this.moreInfo[sectionName + '-credit_union']
          : this.moreInfo[sectionName + '-bank']
      else return this.moreInfo[sectionName]
    },
    showMoreInfo(sectionName) {
      return ['Offering', 'Strategy', 'Technographic Vendors'].includes(
        sectionName
      )
    },
    updateDisplayNameInSections() {
      this.userSections.forEach(section => {
        section.displayName = this.sectionTitle(section.name)
      })
    },
    sectionTitle(sectionName) {
      if (sectionName !== 'Bank Profile') {
        return sectionName
      } else {
        if (this.selectedInstitution.fiType == 'bhc') {
          return 'BHC Profile'
        } else if (this.selectedInstitution.fiType == 'credit_union') {
          return 'CU Profile'
        } else {
          return 'Bank Profile'
        }
      }
    },
    componentName(sectionName) {
      return sectionName.replace(/\W/g, '')
    },
    manageSection(section) {
      this.selectedConfigSection = section
      this.showSectionConfigModal = true
    },

    showBDIEdit(section) {
      if (
        section.name === 'Technographic Vendors' ||
        section.name === 'Offering'
      ) {
        return true
      } else return false
    },
    selectedChildComponent() {
      if (!this.selectedSection) {
        this.selectedSection = this.enabledSections[0]
      }
      if (this.selectedSection) {
        return (
          this.$refs.profileSection &&
          this.$refs.profileSection.find(
            section => section.$data.sectionName === this.selectedSection.name
          )
        )
      }
    },
    setSelectedSection(section) {
      this.selectedSection = section
      if (this.selectedSection) {
        this.selectedChildComponent().configChanged()
      }
    },
    configUpdated() {
      if (this.reportProgress === 100) {
        this.selectedChildComponent().configChanged()
        this.showSectionConfigModal = false
        this.fetchSectionConfigs()
      }
    },
    bdiDataUpdated() {
      this.selectedChildComponent().bdiDataChanged()
    },
    closeModal() {
      this.showSectionConfigModal = false
    },
    downloadXlsx() {
      let params = {
        fiId: this.selectedInstitution.id,
        marketShareFIType: this.marketShareFIType
      }
      this.$wait.start('downloadingReport')
      this.$toasted.show('Started downloading Profile Report', {
        icon: 'print',
        type: 'success'
      })
      downloadsAPI
        .xlsxDownload(params)
        .then(() => {
          this.$toasted.show('Profile report successfully downloaded', {
            icon: 'print',
            type: 'success'
          })
        })
        .finally(() => {
          this.$wait.end('downloadingReport')
        })
    },
    printProfile() {
      this.$wait.start('preparingPrint')
      this.$toasted.show('Preparing data, please wait ...', {
        icon: 'print',
        type: 'success'
      })

      let contentsToLoad = this.pdfEnabledSections.map(section => {
        let actionName = `Profile/fetch${section.name.replace(
          /[\s|&]/gi,
          ''
        )}Details`

        return this.$store.dispatch(actionName, {
          fiId: this.selectedInstitution.id,
          sectionId: section.id
        })
      })

      Promise.all(contentsToLoad).then(() => {
        this.$nextTick(() => {
          setTimeout(
            function() {
              this.$nextTick(() => {
                this.$toasted.clear()
                window.print()
                this.$wait.end('preparingPrint')
              })
            }.bind(this),
            250
          )
        })
      })
    }
  }
}
</script>
<style lang="scss" src="../scss/profile.scss"></style>
<style lang="scss" scoped>
.profile-card {
  .btn {
    background: #f1f3f5;
    text-align: left;
    margin-top: 0;
    border: none;
  }

  .card {
    a.btn-secondary:focus {
      box-shadow: none;
    }
  }

  .card:not(.active-card) {
    &:hover {
      box-shadow: 0 0 0 0.1rem rgba(164, 183, 193, 0.5);
    }
  }

  /deep/ .repositioning {
    border: solid 2px #536c79;
    border-style: dotted;
    border-radius: 5px;
    min-height: 3rem;
  }

  /deep/ .info-modal {
    color: initial;
  }

  .xlsx-link {
    vertical-align: bottom;
  }

  .profile-icon-tray {
    div {
      display: inline;
    }
  }
}

.custom-profile-pdf {
  display: none;
}

.icon-disabled {
  cursor: not-allowed;
  color: #e6e6e6;
}
</style>

<style lang="scss">
@media print {
  @page {
    size: A3;
  }
  .page-card.fin-profile {
    // overflow: visible !important;
    .page-card-with-logo {
      display: none;
    }
    .small-font-on-print {
      font-size: 0.5rem;
    }
    .small-font-on-print-offering {
      font-size: 0.75rem;
      .progress {
        height: 0.5rem;
      }
    }
    .empty-print-only {
      display: block !important;
    }
    .custom-profile {
      padding-top: 0;
      //overflow: visible !important;
      .custom-profile-tabs {
        display: none;
      }

      .custom-profile-pdf {
        display: block;
        overflow: visible !important;
        .profile-section {
          overflow: visible !important;
          page-break-after: always;
          //break-after: always;
          .metric-card,
          .theme-card,
          .offering-card,
          .performance-card {
            page-break-inside: avoid;
            //break-inside: avoid;
          }
        }
        .strategy-card {
          .custom-legend {
            -webkit-print-color-adjust: exact;
          }
        }

        .card-header,
        .market-card-header,
        .market-card,
        .offering-card,
        .performance-card {
          -webkit-print-color-adjust: exact;
        }
      }
    }
  }
}
</style>
