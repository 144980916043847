<template>
  <div class="container--advisor-report offering-section">
    <v-wait for="loadingOfferingData">
      <template slot="waiting">
        <content-placeholders :rounded="true">
          <content-placeholders-text :lines="20"></content-placeholders-text>
        </content-placeholders>
      </template>
      <div class="row mb-0 small-font-on-print">
        <div class="col-sm-8"></div>
        <div :class="[!isMobile ? 'text-right col-sm-4' : 'col-12']">
          <div><b>Peer Group:</b> {{ peerGroup }}</div>
        </div>
      </div>
      <b-card-group columns>
        <OfferingCard
          :offering="offering"
          :renderedForPDF="renderedForPDF"
          v-for="(offering, index) in offeringCards"
          :key="index"
        ></OfferingCard>
      </b-card-group>
    </v-wait>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import OfferingCard from './cards/OfferingCard'

export default {
  name: 'Offering',
  components: {
    OfferingCard
  },
  props: {
    sectionInfo: {
      type: Object,
      required: true,
      default() {
        return {}
      }
    },
    renderedForPDF: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  created() {},
  data() {
    return {
      sectionName: 'Offering'
    }
  },
  computed: {
    ...mapState('Profile', {
      selectedInstitution: state => state.selectedInstitution,
      sectionConfigs: state => state.sectionConfigs,
      offeringCards: state => state.offeringCards,
      peerGroup: state => state.peerGroup,
      isMobile: state => state.isMobile
    }),
    metricConfigs() {
      return this.sectionConfigs['Offering']
    }
  },
  methods: {
    ...mapMutations('Profile', ['setOfferingDetails']),
    ...mapActions('Profile', ['fetchOfferingDetails']),
    loadOfferingData() {
      this.$wait.start('loadingOfferingData')
      return this.fetchOfferingDetails({
        fiId: this.selectedInstitution.id
      }).then(() => {
        this.$wait.end('loadingOfferingData')
      })
    },
    bdiDataChanged() {
      this.setOfferingDetails([])
      this.loadOfferingData()
    },
    configChanged() {
      this.loadOfferingData()
    }
  },
  watch: {}
}
</script>

<style lang="scss" scoped></style>
