<template>
  <div class="growth-section">
    <v-wait for="loadingGrowthData">
      <template slot="waiting">
        <content-placeholders :rounded="true">
          <content-placeholders-text :lines="20"></content-placeholders-text>
        </content-placeholders>
      </template>
      <div
        v-if="growthCards.length"
        :class="{ processing: updatingCardPositions }"
      >
        <div class="row mb-2">
          <div class="col-sm-8">
            <h5>Insight into the key drivers of growth</h5>
          </div>
          <div class="col-sm-4">
            <div class="row">
              <div class="col-sm-12">
                <b>Current Period:</b> {{ currentPeriod }}
              </div>
              <div class="col-sm-12"><b>Time Span:</b> {{ span }}</div>
              <div class="col-sm-12"><b>Peer Group:</b> {{ peerGroup }}</div>
            </div>
          </div>
        </div>
        <div class="row">
          <div
            class="col-sm-4 p-0"
            v-for="(colCards, index) in colGroupedCards"
            :key="index"
          >
            <draggable
              :class="[{ repositioning: repositioning }, 'mx-2']"
              :list="colCards"
              group="col1"
              @change="saveCardPosition"
              @start="dragStart"
              @end="dragEnd"
            >
              <div v-for="(metricDetail, index) in colCards" :key="index">
                <div class="col-sm-12 px-1">
                  <component
                    v-bind:is="cardTypes[metricDetail.card.category]"
                    :metricDetail="metricDetail"
                    :showPeers="canShowPeers()"
                    :showGraph="canShowGraph"
                    :renderedForPDF="renderedForPDF"
                    section="Growth"
                  ></component>
                </div>
              </div>
            </draggable>
          </div>
        </div>
      </div>
    </v-wait>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import MetricCard from './cards/MetricCard'
import ThemeCard from './cards/ThemeCard'
import draggable from 'vuedraggable'
import _ from 'lodash'
import userProfileAPI from '../api/user.profile'

export default {
  name: 'Growth',
  components: {
    MetricCard,
    ThemeCard,
    draggable
  },
  props: {
    sectionInfo: {
      type: Object,
      required: true,
      default() {
        return {}
      }
    },
    renderedForPDF: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  created() {},
  data() {
    return {
      sectionName: 'Growth',
      colGroupedCards: [],
      updatingCardPositions: false,
      repositioning: false
    }
  },
  computed: {
    ...mapState('Profile', {
      selectedInstitution: state => state.selectedInstitution,
      peerGroup: state => state.peerGroup,
      span: state => state.span,
      currentPeriod: state => state.currentPeriod,
      growthCards: state => state.growthCards,
      sectionConfigs: state => state.sectionConfigs
    }),
    metricConfigs() {
      return this.sectionConfigs['Growth']
    },
    cardTypes() {
      return {
        metric: 'MetricCard',
        theme: 'ThemeCard'
      }
    },
    canShowGraph() {
      let config = this.metricConfigs.others.find(
        config => config.category === 'graph'
      )
      return config && config.selected_value === 'Yes'
    }
  },
  methods: {
    ...mapActions('Profile', ['fetchGrowthDetails']),
    canShowPeers() {
      let peerConfig = this.metricConfigs.global.find(
        config => config.category === 'show_peer'
      )
      return peerConfig && peerConfig.selected_value === 'Yes'
    },
    loadGrowthMetricData() {
      let options = {
        fiId: this.selectedInstitution.id,
        sectionId: this.sectionInfo.id
      }
      this.$wait.start('loadingGrowthData')

      return this.fetchGrowthDetails(options).then(() => {
        this.$wait.end('loadingGrowthData')
      })
    },
    configChanged() {
      this.loadGrowthMetricData()
    },
    dragStart() {
      this.repositioning = true
    },
    dragEnd() {
      this.repositioning = false
    },
    saveCardPosition(type) {
      if (type.added || type.moved) {
        this.calculateCardPosition()

        let cardsInfo = this.colGroupedCards.reduce((cardData, colCards) => {
          return cardData.concat(
            colCards.map(colCard => this.filterApiAttrs(colCard))
          )
        }, [])

        this.updatingCardPositions = true

        userProfileAPI
          .updateCardPosition({
            section_id: this.sectionInfo.id,
            cards: cardsInfo
          })
          .then(() => {
            this.$toasted.show('Card order updated successfully.', {
              icon: 'user-circle',
              type: 'success'
            })
            this.updatingCardPositions = false
          })
      }
    },
    calculateCardPosition() {
      this.colGroupedCards.forEach((colCards, colIndex) => {
        colCards.forEach((card, rowIndex) => {
          card.card.card_column = colIndex + 1
          card.card.card_row = rowIndex + 1
        })
      })
    },
    filterApiAttrs(colCard) {
      return {
        card_id: colCard.card.id,
        column: colCard.card.card_column,
        row: colCard.card.card_row,
        configuration_id: colCard.configuration_id
      }
    }
  },
  watch: {
    growthCards: {
      handler: function() {
        let groupedCards = _.mapValues(
          _.groupBy(this.growthCards, 'card.card_column')
        )
        this.colGroupedCards = [
          (groupedCards[1] || []).sort((c1, c2) =>
            c1.card.card_row > c2.card.card_row ? 1 : -1
          ),
          (groupedCards[2] || []).sort((c1, c2) =>
            c1.card.card_row > c2.card.card_row ? 1 : -1
          ),
          (groupedCards[3] || []).sort((c1, c2) =>
            c1.card.card_row > c2.card.card_row ? 1 : -1
          )
        ]
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped></style>
