<template>
  <b-card
    body-class="market-card p-1"
    header-class="market-card-header"
    class="std-border mb-0"
  >
    <div slot="header">
      <h5 class="mb-0">Field of Membership</h5>
    </div>
    <div class="text-center">
      <h5 class="p-2">{{ cardDetail.market_data.value }}</h5>
    </div>
  </b-card>
</template>

<script>
export default {
  name: 'FieldOfMembership',
  props: {
    cardDetail: {
      type: Object,
      required: true,
      default() {
        return {}
      }
    }
  },
  data() {
    return {}
  }
}
</script>

<style lang="scss" scoped></style>
