<template>
  <b-card
    no-body
    :class="[
      { 'pdf-export': renderedForPDF },
      'overflow-hidden std-border strategy-card p-3 mb-0 my-2'
    ]"
    body-class="p-1"
  >
    <b-row>
      <b-col sm="12">
        <h5 class="mb-0">{{ cardDetail.name }}</h5>
        <p>{{ cardDetail.sub_title }}</p>
      </b-col>
      <b-col sm="12">
        <Highcharts :options="chartOptions" ref="strategyChart"></Highcharts>
        <div class="row justify-content-center">
          <div class="col-sm-10">
            <div class="px-2 custom-legend-holder">
              <div
                class="row"
                v-for="(value, index) in cardDetail.values"
                :key="index"
              >
                <div class="col-sm-12 pr-0 d-flex align-items-start">
                  <div
                    class="custom-legend"
                    :style="{ 'background-color': colorCodes[index] }"
                  ></div>
                  <div class="custom-legend-label pl-1 mb-2">
                    {{ value.name }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { Chart } from 'highcharts-vue'

export default {
  name: 'StrategyCard',
  components: {
    Highcharts: Chart
  },
  props: {
    cardDetail: {
      type: Object,
      required: true,
      default() {
        return {}
      }
    },
    renderedForPDF: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      colorCodes: [
        '#EAE9EA',
        '#A5A5A5',
        '#ABCBE5',
        '#6EB1EA',
        '#0A80AA',
        '#F4D03F',
        '#5F6A6A',
        '#34495E'
      ]
    }
  },
  mounted() {
    if (this.renderedForPDF && window.matchMedia) {
      let chart = this.$refs.strategyChart.chart
      let mediaQueryList = window.matchMedia('print')

      mediaQueryList.addListener(() => {
        chart.reflow()
      })
    }
  },
  computed: {
    chartOptions() {
      return {
        credits: {
          enabled: false
        },
        chart: {
          type: 'pie',
          backgroundColor: this.renderedForPDF ? '#FFF' : '#20A8D8',
          height: 250
        },
        exporting: {
          enabled: false
        },
        title: {
          text: null
        },
        legend: {
          enabled: false
        },
        tooltip: {
          enabled: true,
          formatter: function() {
            return `${this.key} : ${this.point.y}%`
          }
        },
        plotOptions: {
          pie: {
            size: '110%',
            borderWidth: 2,
            dataLabels: {
              enabled: true,
              allowOverlap: false,
              distance: -35,
              formatter: function() {
                if (this.point.y >= 2) {
                  if (this.point.y >= 7) {
                    return this.point.y + '%'
                  } else {
                    return `<span style="fill: black;">${this.point.y}%</span>`
                  }
                } else {
                  return ''
                }
              },
              style: {
                textOutline: 0,
                fontSize: 12
              }
            },
            showInLegend: true,
            center: ['50%', '50%']
          }
        },
        series: [
          {
            name: 'Strength',
            innerSize: '40%',
            data: this.cardDetail.values.map((card, index) => {
              return {
                name: card.name,
                y: parseFloat(card.percentage),
                actual_value: card.value,
                color: this.colorCodes[index]
              }
            })
          }
        ]
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.strategy-card {
  background-color: #20a8d8;
  color: white;

  .custom-legend-holder {
    min-height: 10rem;

    .custom-legend {
      display: inline-flex;
      width: 15px;
      height: 15px;
      border: solid 2px white;
    }

    .custom-legend-label {
      display: inline-flex;
      font-weight: bold;
      font-size: smaller;
    }
  }

  &.pdf-export {
    background-color: #fff;
    color: #0a80aa;

    &.std-border {
      border-color: #0a80aa;
    }

    .custom-legend {
      border-color: #0a80aa;
    }
  }
}
</style>
