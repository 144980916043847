<template>
  <div>
    <b-modal
      size="lg"
      id="userSectionModal"
      title="User Sections"
      @show="setInitData"
      @hide="hideModal"
      ref="userSectionModal"
      hide-footer
      no-close-on-backdrop
      no-close-on-esc
    >
      <b-table striped small show-empty :items="userSections" :fields="fields">
        <template v-slot:cell(status)="data">
          <c-switch
            type="text"
            variant="success"
            on="Yes"
            off="No"
            :pill="true"
            size="sm"
            :checked="data.item.status"
            v-model="data.item.status"
          />
        </template>
      </b-table>

      <div class="row pt-4">
        <div class="col-sm-12 text-center">
          <b-button
            size
            variant="secondary"
            class="fw-btn mx-3"
            @click="closeModal"
            >Cancel</b-button
          >
          <b-button
            size=""
            variant="primary"
            class="fw-btn mx-3"
            @click="saveUserSections"
            >Save</b-button
          >
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import cSwitch from '@/components/Switch'
import { mapActions } from 'vuex'

export default {
  name: 'UserSectionSelection',
  components: {
    cSwitch
  },
  props: {
    sections: {
      type: Array,
      required: true,
      default() {
        return []
      }
    }
  },
  created() {},
  data() {
    return {
      userSections: [],
      fields: [
        { key: 'displayName', label: 'Section' },
        { key: 'status', label: 'Enabled' }
      ]
    }
  },
  computed: {},
  methods: {
    ...mapActions('Profile', ['updateUserSections']),
    setInitData() {
      this.userSections = JSON.parse(JSON.stringify(this.sections))
    },
    saveUserSections() {
      this.updateUserSections(this.userSections).then(() => {
        this.$toasted.show('User Section preferences updated successfully.', {
          icon: 'user-circle',
          type: 'success'
        })
        this.closeModal()
      })
    },
    hideModal() {},
    closeModal() {
      this.$refs.userSectionModal.hide()
    }
  }
}
</script>

<style lang="scss" scoped></style>
