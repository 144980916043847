<template>
  <v-wait for="fetchMergerDetails">
    <template slot="waiting">
      <content-placeholders :rounded="true">
        <content-placeholders-text :lines="7"></content-placeholders-text>
      </content-placeholders>
    </template>
    <div :class="[isMobile ? 'row' : '']">
      <div :class="[isMobile ? 'col-12' : '']">
        <div :class="[isMobile ? 'merger-history-table' : '']">
          <b-table
            :items="mergerHistory"
            :fields="fields"
            show-empty
            striped
            responsive="true"
          >
            <template v-slot:cell(dt_trans)="data">
              {{ transactionDate(data.item.dt_trans) }}
            </template>
          </b-table>
        </div>
      </div>
    </div>
  </v-wait>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import * as moment from 'moment'

export default {
  name: 'MergerHistory',
  data() {
    return {
      sectionName: 'Merger History'
    }
  },
  props: {
    renderedForPDF: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  created() {},
  updated() {},
  computed: {
    ...mapState('Profile', {
      selectedInstitution: state => state.selectedInstitution,
      mergerHistory: state => state.mergerHistory,
      isMobile: state => state.isMobile
    }),
    fields() {
      return [
        { key: 'institution_name', label: 'Institution' },
        { key: 'city', label: 'City' },
        { key: 'state', label: 'State' },
        { key: 'merging_type', label: 'Merger Type' },
        { key: 'accounting', label: 'Accounting' },
        { key: 'dt_trans', label: 'Merger Date' }
      ]
    }
  },
  methods: {
    ...mapActions('Profile', ['fetchMergerHistoryDetails']),
    fetchMergerDetails() {
      this.$wait.start('fetchMergerDetails')
      return this.fetchMergerHistoryDetails({
        fiId: this.selectedInstitution.id
      }).then(() => {
        this.$wait.end('fetchMergerDetails')
      })
    },
    configChanged() {
      this.fetchMergerDetails()
    },
    transactionDate(date) {
      let transDate = moment(date, 'YYYYMMDD')
      return transDate._isValid ? transDate.format('MM/DD/YYYY') : 'NA'
    }
  },
  watch: {}
}
</script>

<style lang="scss" scoped>
.merger-history-table {
  height: 20rem;
  overflow-y: auto;
}
.pdf-export {
  .merger-history-table {
    height: auto;
    overflow-y: auto;
  }
}
</style>
<style lang="scss">
.merger-history-table {
  table {
    @media (min-width: 992px) {
      table-layout: fixed;
    }

    @media (max-width: 991.98px) {
      display: block;
      width: 100%;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
      -ms-overflow-style: -ms-autohiding-scrollbar;
    }
  }
}
</style>
