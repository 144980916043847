<template>
  <b-card
    body-class="market-card p-1"
    header-class="market-card-header"
    class="std-border mb-0"
  >
    <div slot="header">
      <b-row>
        <b-col cols="10">
          <h5 class="mb-0">Market Size</h5>
        </b-col>
        <b-col cols="2" class="text-right mb-0 pt-1">
          <i
            class="icon-info icons small-line-icon font-weight-bold cursor-pointer"
            @click="$refs[`info-marketSize`].show()"
          ></i>
        </b-col>
        <b-modal
          size="lg"
          ref="info-marketSize"
          class="info-modal"
          title="Market Size"
          ok-only
        >
          <div>
            <p>
              Market Size is segmented utilizing the NCHS Urban-Rural
              Classification Scheme for Counties. NCHS has developed a six-level
              urban-rural classification scheme for U.S. counties and county-
              equivalent entities.The scheme has six (6) market size
              classifications: Large Central Metro, Large Fringe Metro, Medium
              Metro, Small Metro, Micropolitan and Rural (non-core). To classify
              a financial institutions footprint, the analysis aggregates all of
              its county market and produce an average for the counties. The
              NCHS classification is quantitative based on county population.
              Metropolitan counties are either: Large Central Metro counties in
              MSA of 1 million population that: 1) contain the entire population
              of the largest principal city of the MSA, or 2) are completely
              contained within the largest principal city of the MSA, or 3)
              contain at least 250,000 residents of any principal city in the
              MSA. Large Fringe Metro are counties in MSA of 1 million or more
              population that do not qualify as Large Central Metro. Medium
              Metro are counties in MSA of 250,000-999,999 population. Small
              Metro counties are counties in MSAs of less than 250,000
              population. Non-Metropolitan counties: Micropolitan counties in
              micropolitan statistical area; Rural (Non-Core) counties are not
              in micropolitan statistical areas.
            </p>
          </div>
        </b-modal>
      </b-row>
    </div>
    <div class="text-center metro-card">
      <h5 class="p-2">{{ cardDetail.market_data.value }}</h5>
      <div :class="['profile-sprite', marketIcon]"></div>
      <h6 class="p-2" v-html="'&nbsp;'"></h6>
    </div>
  </b-card>
</template>

<script>
export default {
  name: 'MarketSize',
  props: {
    cardDetail: {
      type: Object,
      required: true,
      default() {
        return {}
      }
    }
  },
  data() {
    return {}
  },
  computed: {
    marketIcon() {
      if (!this.cardDetail.market_data.value) {
        return ''
      }
      return this.cardDetail.market_data.value
        .toLocaleLowerCase()
        .split(' ')
        .join('-')
    }
  }
}
</script>

<style lang="scss" scoped></style>
