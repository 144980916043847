<template>
  <div class="section-config">
    <b-modal
      v-model="showModal"
      size="xl"
      id="sectionConfigModal"
      modal-class="section-config-modal"
      footer-class="justify-content-center"
      :title="modalTitle + ' Options'"
      @show="setInitData"
      hide-header-close
      no-close-on-backdrop
      no-close-on-esc
      scrollable
    >
      <template slot="modal-footer">
        <div class="row">
          <div class="col-sm-12">
            <b-button
              size
              variant="secondary"
              class="fw-btn mx-3"
              @click="closeModal"
              :disabled="processing"
              >Cancel</b-button
            >
            <b-button
              size=""
              variant="primary"
              class="fw-btn mx-3"
              @click="saveModal"
              :disabled="processing"
              >Save</b-button
            >
          </div>
        </div>
      </template>
      <v-wait for="loadingMetricsData">
        <template slot="waiting">
          <content-placeholders :rounded="true" class="pt-4">
            <content-placeholders-text :lines="20"></content-placeholders-text>
          </content-placeholders>
        </template>

        <div v-if="sectionConfigs">
          <div v-if="isMetricSection">
            <div class="row">
              <div class="col-sm-12">
                <h6>
                  <b>{{ this.section.name }} Metric Options</b>
                </h6>
              </div>
            </div>

            <div class="row mb-1">
              <div class="col-sm-12">
                <h6>
                  Select Metrics to Display in the
                  {{ this.section.name }} Section
                </h6>
              </div>
            </div>

            <div>
              <b-card no-header class="std-border">
                <div class="row">
                  <div class="col-sm-3">
                    <div
                      class="card std-border mb-0 metric-menu tree-menu segment-column"
                    >
                      <div class="card-body p-0">
                        <div>
                          <div class="font-weight-bold px-2 pt-2">
                            Categories
                          </div>
                          <ul class="list-group">
                            <li
                              :class="[
                                {
                                  active:
                                    selectedMetric.menu_display ===
                                    menu.menu_display
                                },
                                'list-group-item pl-4'
                              ]"
                              v-for="(menu, index) in menuData"
                              :key="index"
                              @click="setActive(menu)"
                            >
                              {{ menu.menu_display }}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div :class="['col-sm-9', 'px-2 details-holder']">
                    <div
                      class="card std-border mb-0 metric-details segment-column"
                    >
                      <div class="card-body p-1">
                        <v-wait for="loadingTreeData">
                          <template slot="waiting">
                            <content-placeholders
                              :rounded="true"
                              class="loader"
                            >
                              <content-placeholders-text
                                :lines="20"
                              ></content-placeholders-text>
                            </content-placeholders>
                          </template>

                          <div class="row p-3">
                            <div
                              class="offset-sm-4 col-sm-2 text-right align-self-center"
                            >
                              <h6 class="mb-0">Search</h6>
                            </div>

                            <div class="col-sm-6 pl-0">
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Type text to search..."
                                v-model="searchText"
                              />
                            </div>
                          </div>

                          <LiquorTree
                            :data="selectedMetricData"
                            :multiple="false"
                            :showChildren="true"
                            :options="treeOptions"
                            :filter="searchText"
                            @node:clicked="nodeClicked"
                            @node:checked="nodeChecked"
                            @node:unchecked="nodeUnChecked"
                            ref="metricTree"
                          >
                            <span class="tree-text" slot-scope="{ node }">
                              <template>
                                <span :id="'fm-' + node.id.toString()">
                                  {{ node.text }}
                                </span>
                                <b-popover
                                  :target="'fm-' + node.id.toString()"
                                  triggers="hover"
                                  placement="right"
                                  boundary="window"
                                  v-if="node.data.sec_title"
                                >
                                  <div class="font-weight-bold">
                                    {{ node.data.sec_title }}
                                  </div>
                                  {{
                                    getMatchingDescription(node.data.metric_id)
                                      .description
                                  }}
                                </b-popover>
                              </template>
                            </span>
                          </LiquorTree>
                        </v-wait>
                      </div>
                    </div>
                  </div>
                </div>
              </b-card>
            </div>
          </div>

          <div class="my-3" v-if="otherConfigs.length">
            <div class="row" v-for="config in otherConfigs">
              <div class="col-sm-12" v-if="config.category === 'theme'">
                <div :class="[peerEnabled ? '' : 'disable-option', 'row']">
                  <div class="col-sm-4">
                    <h6>{{ config.label }}</h6>
                    <div class="label-sub-info">
                      Show Peer Group is required
                    </div>
                  </div>
                  <div class="col-sm-2">
                    <c-switch
                      type="text"
                      variant="success"
                      on="Yes"
                      off="No"
                      :pill="true"
                      size="sm"
                      :checked="config.selected_value === 'Yes'"
                      @change="updateConfig($event, config)"
                      :disabled="!peerEnabled"
                    ></c-switch>
                    <div class="d-inline-block pl-3">
                      <i
                        class="icon-info icons small-line-icon cursor-pointer"
                        id="popover-theme-info"
                      ></i>
                    </div>
                    <b-popover
                      target="popover-theme-info"
                      triggers="hover"
                      placement="right"
                    >
                      <template v-slot:title>
                        <div class="popover-info-header">
                          <h6 class="mb-1"><b>Primary Theme</b></h6>
                        </div>
                      </template>
                      <div class="popover-content">
                        <ThemeInfo
                          :isCreditUnion="isCreditUnionSelected"
                        ></ThemeInfo>
                      </div>
                    </b-popover>
                  </div>
                </div>
              </div>
              <div v-else-if="config.input_type === 'select'" class="col-sm-12">
                <div class="row">
                  <div class="col-sm-4">
                    <h6>{{ config.label }}</h6>
                  </div>
                  <div class="col-sm-2">
                    <b-form-select
                      :options="config.values"
                      v-model="config.selected_value"
                    ></b-form-select>
                  </div>
                </div>
              </div>
              <div class="col-sm-12" v-else>
                <div class="row">
                  <div class="col-sm-4">
                    <h6>{{ config.label }}</h6>
                  </div>
                  <div class="col-sm-2">
                    <c-switch
                      type="text"
                      variant="success"
                      on="Yes"
                      off="No"
                      :pill="true"
                      size="sm"
                      :checked="config.selected_value === 'Yes'"
                      @change="updateConfig($event, config)"
                    ></c-switch>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-if="retailServiceConfigs.length" class="mb-3">
            <div class="row">
              <div class="col-sm-12">
                <h6><b>Retail Services</b></h6>
              </div>
            </div>

            <div
              class="row pl-3"
              v-for="(config, index) in retailServiceConfigs"
            >
              <div class="col-sm-5">
                <h6>{{ config.label }}</h6>
              </div>
              <div class="col-sm-3">
                <b-form-checkbox
                  :id="'rs_config_' + index"
                  v-model="config.selected_value"
                  value="Yes"
                  unchecked-value="No"
                ></b-form-checkbox>
              </div>
            </div>
          </div>

          <div v-if="businessServiceConfigs.length" class="mb-3">
            <div class="row">
              <div class="col-sm-12">
                <h6><b>Business Services</b></h6>
              </div>
            </div>

            <div
              class="row pl-3"
              v-for="(config, index) in businessServiceConfigs"
            >
              <div class="col-sm-5">
                <h6>{{ config.label }}</h6>
              </div>
              <div class="col-sm-3">
                <b-form-checkbox
                  :id="'bs_config_' + index"
                  v-model="config.selected_value"
                  value="Yes"
                  unchecked-value="No"
                ></b-form-checkbox>
              </div>
            </div>
          </div>

          <div class="row" v-if="section.name !== 'Digital Channels'">
            <div class="col-sm-12">
              <h6><b>Global Options</b></h6>
            </div>
          </div>

          <div
            class="row"
            v-for="config in globalConfigs"
            v-if="section.name !== 'Digital Channels'"
          >
            <div :class="[extendedLayout ? 'col-sm-5' : 'col-sm-4']">
              <h6>{{ config.label }}</h6>
            </div>
            <div class="col-sm-4">
              <div v-if="config.input_type === 'toggle'">
                <c-switch
                  type="text"
                  variant="success"
                  on="Yes"
                  off="No"
                  :pill="true"
                  size="sm"
                  :checked="config.selected_value === 'Yes'"
                  @change="updateConfig($event, config)"
                ></c-switch>
              </div>
              <div v-else-if="config.input_type === 'select'" class="mb-1">
                <b-form-select
                  :options="config.values"
                  v-model="config.selected_value"
                ></b-form-select>
              </div>
            </div>
          </div>
        </div>
      </v-wait>
    </b-modal>
  </div>
</template>

<script>
// global
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
import _ from 'lodash'
// api
import reportMenuAPI from '@/api/finapps/report_menu_items'
import metricTreeRelationshipsAPI from '@/api/finapps/metric_tree_relationships'
// ui components
import cSwitch from '@/components/Switch'
import ThemeInfo from './ThemeInfo'
import LiquorTree from 'liquor-tree'

export default {
  name: 'SectionConfig',
  components: {
    cSwitch,
    ThemeInfo,
    LiquorTree
  },
  props: {
    showModal: {
      required: true,
      default: false
    },
    section: {
      type: Object,
      required: true,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      sectionConfigs: {
        global: [],
        others: [],
        metrics: []
      },
      processing: false,
      selectedMetric: {},
      selectedMetricData: {},
      treeOptions: {
        checkbox: true,
        checkOnSelect: false,
        parentSelect: false,
        autoCheckChildren: false,
        autoDisableChildren: false
      },
      isSCorp: false,
      searchText: null,
      metricDescriptions: null
    }
  },
  computed: {
    ...mapGetters('Profile', ['isCreditUnionSelected']),
    ...mapState('Profile', {
      configs: state => state.sectionConfigs,
      selectedInstitution: state => state.selectedInstitution
    }),
    ...mapState('ReportWriter', {
      metricMenuData: state => state.metricMenuData,
      metricTreeData: state => state.metricTreeData
    }),
    treeID() {
      switch (this.fiType) {
        case 'bank':
          return this.isSCorp ? 2 : 1
        case 'bhc':
          return 1
        case 'credit_union':
          return 3
        default:
          return 1
      }
    },
    fiType() {
      if (this.selectedInstitution.id) {
        return this.selectedInstitution.fiType
      }
    },
    sectionMetrics() {
      return this.sectionConfigs.metrics.filter(
        metric => metric.category === 'metric'
      )
    },
    otherConfigs() {
      return this.sectionConfigs['others'].sort((m1, m2) =>
        m1.label >= m2.label ? 1 : -1
      )
    },
    globalConfigs() {
      return this.sectionConfigs['global'].sort((g1, g2) =>
        g1.input_type <= g2.input_type ? 1 : -1
      )
    },
    businessServiceConfigs() {
      return (this.sectionConfigs['business_services'] || []).sort((m1, m2) =>
        m1.label >= m2.label ? 1 : -1
      )
    },
    retailServiceConfigs() {
      return (this.sectionConfigs['retail_services'] || []).sort((m1, m2) =>
        m1.label >= m2.label ? 1 : -1
      )
    },
    extendedLayout() {
      return (
        this.businessServiceConfigs.length || this.retailServiceConfigs.length
      )
    },
    peerConfig() {
      return this.globalConfigs.find(metric => metric.category === 'show_peer')
    },
    themeConfig() {
      return this.otherConfigs.find(metric => metric.category === 'theme')
    },
    peerEnabled() {
      return this.peerConfig && this.peerConfig.selected_value === 'Yes'
    },
    modalTitle() {
      return this.section.name === 'Profit'
        ? 'Profitability'
        : this.section.name
    },
    isMetricSection() {
      return ['Profit', 'Growth', 'Risk'].includes(this.section.name)
    },
    formDataParams() {
      return {
        section_id: this.sectionConfigs.section_id,
        global: this.sectionConfigs.global.map(config =>
          _.pick(config, ['configuration_id', 'selected_value'])
        ),
        others: this.sectionConfigs.others.map(config =>
          _.pick(config, ['configuration_id', 'selected_value'])
        ),
        metrics: this.sectionConfigs.metrics.map(config =>
          _.pick(config, ['metric_id', 'selected_value'])
        ),
        business_services: (
          this.sectionConfigs.business_services || []
        ).map(config => _.pick(config, ['bank_service_id', 'selected_value'])),
        retail_services: (
          this.sectionConfigs.retail_services || []
        ).map(config => _.pick(config, ['bank_service_id', 'selected_value']))
      }
    },
    menuData() {
      return this.metricMenuData.filter(
        menu =>
          menu.fi_type === this.fiType &&
          menu.tree_id === this.treeID &&
          menu.product_name === this.section.name
      )
    },
    productMetrics() {
      if (!this.selectedMetric) {
        return []
      }
      return this.metricTreeData[this.treeID].filter(
        td => td.product_id === this.selectedMetric.product_id
      )
    },
    selectedOption() {
      return this.productMetrics.find(
        pm => pm.metric_id === this.selectedMetric.metric_id
      )
    },
    metricIDsToBeSkipped() {
      if (!this.selectedMetric) {
        return []
      }

      return this.menuData
        .filter(md => md.metric_id !== this.selectedMetric.metric_id)
        .map(md => md.metric_id)
    }
  },
  methods: {
    ...mapActions('Profile', ['updateSectionConfigs', 'resetReportProgress']),
    ...mapMutations('ReportWriter', ['setMetricMenuData', 'setMetricTreeData']),
    setInitData() {
      if (this.isMetricSection) {
        this.loadMetricTreeDetails()
      }

      this.sectionConfigs = JSON.parse(
        JSON.stringify(this.configs[this.section.name])
      )
    },
    loadMetricTreeDetails() {
      this.$wait.start('loadingMetricsData')

      Promise.all([
        this.getMetricMenu(),
        this.getMetricTreeData(),
        this.getMetricTreeDescriptions()
      ]).then(() => {
        this.setDefaultMetric()
        this.$wait.end('loadingMetricsData')

        this.$nextTick(() => {
          this.prepareTree()
        })
      })
    },
    getMetricMenu() {
      if (this.metricMenuData.length) {
        return Promise.resolve()
      }

      this.$wait.start('loadingMenu')

      return reportMenuAPI
        .all()
        .then(res => {
          this.setMetricMenuData(res.menu_items)
        })
        .finally(() => {
          this.$wait.end('loadingMenu')
        })
    },
    getMetricTreeData() {
      if (Object.keys(this.metricTreeData).length) {
        return Promise.resolve()
      }

      this.$wait.start('loadingTreeData')

      return metricTreeRelationshipsAPI
        .treeRelationships(this.treeID)
        .then(res => {
          this.setMetricTreeData(res.relationships)
        })
        .finally(() => {
          this.$wait.end('loadingTreeData')
        })
    },
    getMetricTreeDescriptions() {
      if (this.metricDescriptions) {
        return Promise.resolve()
      }

      return metricTreeRelationshipsAPI.metricDescriptions().then(res => {
        this.metricDescriptions = res.relationships
      })
    },
    getMatchingDescription(metricID) {
      if (!this.metricDescriptions) {
        return {}
      }

      return (
        this.metricDescriptions[this.treeID].find(
          m => m.metric_id === metricID
        ) || {}
      )
    },
    setDefaultMetric() {
      this.selectedMetric = this.menuData[0]
    },
    prepareTree() {
      this.treeData = this.createTreeNode(
        this.selectedOption,
        this.productMetrics
      )
      this.$nextTick(() => {
        this.$refs.metricTree.setModel(this.treeData)
      })
    },
    createTreeNode(metric, metrics) {
      return {
        id: metric.id,
        text: metric.tree_display,
        data: metric,
        state: {
          expanded: true,
          checked: this.sectionMetrics.find(
            sm => sm.metric_id === metric.metric_id
          )
            ? true
            : false,
          disabled: false
        },
        children: this.convertToTree(metrics, metric.key_id)
      }
    },
    convertToTree(metrics = [], id = null, link = 'key_parent_id') {
      return metrics
        .filter(
          metric =>
            metric[link] === id &&
            !this.metricIDsToBeSkipped.includes(metric.metric_id)
        )
        .map(metric => this.createTreeNode(metric, metrics))
    },
    setActive(menu) {
      this.selectedMetric = menu
      this.searchText = null
      this.prepareTree()
    },
    saveModal() {
      this.processing = true
      this.resetReportProgress()
      this.updateSectionConfigs(this.formDataParams).then(() => {
        this.$toasted.show('User preferences updated successfully.', {
          icon: 'user-circle',
          type: 'success'
        })
        this.processing = false
        this.$emit('configUpdated')
      })
    },
    updateConfig(event, config) {
      if (config.category === 'show_peer' && !event && this.themeConfig) {
        this.themeConfig.selected_value = 'No'
      }
      config.selected_value = event ? 'Yes' : 'No'
    },
    closeModal() {
      this.searchText = null
      this.$emit('closeModal')
    },
    nodeClicked() {},
    nodeChecked(node) {
      this.sectionConfigs.metrics.push({
        metric_id: node.data.metric_id,
        selected_value: 'Yes',
        category: 'metric'
      })
    },
    nodeUnChecked(node) {
      let metricIndex = this.sectionConfigs.metrics.findIndex(
        metric => metric.metric_id === node.data.metric_id
      )
      this.sectionConfigs.metrics.splice(metricIndex, 1)
    }
  }
}
</script>

<style lang="scss" scoped>
.label-sub-info {
  margin-top: -0.75rem;
  font-size: smaller;
  font-weight: bold;
  color: #909395;
}

.disable-option {
  color: #909395;
  cursor: not-allowed;
}
</style>

<style lang="scss">
.section-config-modal {
  .tree-menu {
    background-color: #f0f3f5;

    .list-group {
      .list-group-item {
        cursor: pointer;
        border: none;
        padding: 0.3rem;
        background-color: transparent;
        font-weight: 500;

        &.active {
          background-color: #20a8d8;
        }

        &:hover {
          background-color: #20a8d8;
        }
      }
    }
  }

  .tree-content {
    padding-top: 0px;
    padding-bottom: 0px;

    i.tree-arrow {
      width: 23px;
      height: 20px;
      margin-left: 0;

      &:after {
        height: 7px;
        width: 7px;
      }
    }
  }

  .tree-anchor {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .tree-checkbox {
    width: 15px !important;
    height: 15px !important;

    &.checked:after {
      left: 4px;
      top: 2px;
      height: 6px;
      width: 3px;
    }
  }

  .tree-node.disabled {
    .tree-content {
      .tree-anchor {
        color: #343434;
      }
    }
  }

  .segment-column {
    height: 20rem;
    overflow-y: auto;
    overflow-x: hidden;

    .sub-title {
      font-size: 13px;
      font-weight: 500;
      color: #6c6d70;
    }
  }

  .segment-disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}
</style>
