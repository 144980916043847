<template>
  <b-card
    body-class="market-card p-1"
    header-class="market-card-header"
    class="std-border mb-0"
  >
    <div slot="header">
      <b-row>
        <b-col cols="10">
          <h5 class="mb-0">Market Coverage</h5>
        </b-col>
        <b-col cols="2" class="text-right mb-0 pt-1">
          <i
            class="icon-info icons small-line-icon font-weight-bold cursor-pointer"
            @click="$refs[`info-marketCoverage`].show()"
          ></i>
        </b-col>
        <b-modal
          size="lg"
          ref="info-marketCoverage"
          class="info-modal"
          title="Market Coverage"
          ok-only
        >
          <div>
            <p>
              The Market Coverage segmentation classifies the footprint type of
              the financial institution into one of seven (7) segments: (1)
              National (2) Regional (3) Interstate (4) Intrastate (5) Multi-
              Community (6) Community or (7) Internet-Only. For the segments
              with physical geographic locations, we'll detail the
              classification segments working from smallest to largest.
              Community institutions have locations only within one county
              market. Multi-Community footprints have locations in more than one
              (1) and less than six (6) counties within a single state.
              Intrastate footprints have locations in six (6) or more counties
              from within a single state. Interstate coverage includes
              institutions with locations in two (2) or more and less than eight
              (8) states. Regional footprints occupy more than eight (8) states
              but less than three (3) U.S. regions. National footprints cover
              three or more U.S. regions and twenty-five (25) or more states .
              Internet-only institutions only serve customers digitally without
              any physical locations as a direct bank or standalone internet
              bank (SAIB).
            </p>
          </div>
        </b-modal>
      </b-row>
    </div>
    <div class="text-center">
      <h5 class="p-2">{{ cardDetail.market_data.coverage }}</h5>
      <div :class="['profile-sprite', marketIcon]"></div>
      <h6 class="p-2">
        Branches in {{ countyCount }}
        {{ countyCount | pluralize(['County', 'Counties']) }}; {{ stateCount }}
        {{ stateCount | pluralize('State') }}
      </h6>
    </div>
  </b-card>
</template>

<script>
export default {
  name: 'MarketCoverage',
  props: {
    cardDetail: {
      type: Object,
      required: true,
      default() {
        return {}
      }
    }
  },
  data() {
    return {}
  },
  computed: {
    marketIcon() {
      if (!this.cardDetail.market_data.coverage) {
        return ''
      }
      return this.cardDetail.market_data.coverage
        .toLocaleLowerCase()
        .split(' ')
        .join('-')
    },
    countyCount() {
      return this.cardDetail && this.cardDetail.market_data.county_count
    },
    stateCount() {
      return this.cardDetail && this.cardDetail.market_data.state_count
    }
  }
}
</script>

<style lang="scss" scoped></style>
