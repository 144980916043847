<template>
  <div class="row">
    <b-col sm="12">
      <b-card class="std-border" body-class="p-1">
        <div slot="header">
          <h6 class="mb-0">Branch Network</h6>
        </div>
        <GmapMap
          ref="marketGMaps"
          :center="{ lat: 40, lng: -100 }"
          :zoom="4"
          map-type-id="terrain"
        >
        </GmapMap>
      </b-card>
    </b-col>
  </div>
</template>

<script>
export default {
  name: 'BranchNetwork',
  props: {
    cardDetail: {
      type: Object,
      required: true,
      default() {
        return {}
      }
    }
  },
  mounted() {
    let locMarkers = this.markers

    this.$refs.marketGMaps.$mapPromise.then(function(map) {
      let bounds = new google.maps.LatLngBounds()

      locMarkers.forEach(marker => {
        let myLatLong = new google.maps.LatLng(
          marker.latitude,
          marker.longitude
        )
        let gmark = new google.maps.Marker({
          position: myLatLong,
          map: map,
          draggable: false,
          title: marker.name,
          label: marker.type.charAt(0).toUpperCase()
        })

        let infoContent =
          '<div><h4>' + marker.name + '</h4><p>' + marker.address + '</p></div>'
        let infoWindow = new google.maps.InfoWindow({ content: infoContent })

        gmark.addListener('click', function() {
          infoWindow.open(map, gmark)
        })
        bounds.extend(myLatLong)
      })
      map.fitBounds(bounds)
      map.panToBounds(bounds)
    })
  },
  data() {
    return {
      markers: []
    }
  },
  watch: {
    cardDetail: {
      handler: function(newVal) {
        if (newVal.market_data) {
          this.markers = newVal.market_data.values
        }
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped></style>

<style lang="scss">
.vue-map-container {
  height: 30rem;
}
</style>
