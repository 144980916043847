<template>
  <div class="technographic-section">
    <v-wait for="loadingTechnographicData">
      <template slot="waiting">
        <content-placeholders :rounded="true">
          <content-placeholders-text :lines="10"></content-placeholders-text>
        </content-placeholders>
      </template>
      <div v-if="!technographicCards.length">
        <div class="row">
          <div class="col-sm-12 p-2 text-center">
            No service with vendor or product found
          </div>
        </div>
      </div>
      <div class="card-deck" v-else>
        <div
          :class="[
            { 'pdf-export': renderedForPDF },
            'card technographic-card std-border p-2 mb-0 my-2 col-sm-4'
          ]"
          v-for="(offering, index) in technographicCards"
          :key="index"
        >
          <TechnographicCard :offering="offering"></TechnographicCard>
        </div>
      </div>
    </v-wait>
  </div>
</template>
<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import TechnographicCard from './cards/TechnographicCard'

export default {
  name: 'TechnographicVendors',
  components: {
    TechnographicCard
  },
  props: {
    renderedForPDF: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  created() {},
  data() {
    return {
      sectionName: 'Technographic Vendors'
    }
  },
  computed: {
    ...mapState('Profile', {
      selectedInstitution: state => state.selectedInstitution,
      technographicCards: state => state.technographicCards
    })
  },
  methods: {
    ...mapMutations('Profile', ['setTechnographicDetails']),
    ...mapActions('Profile', ['fetchTechnographicVendorsDetails']),
    loadTechnographicVendorsData() {
      this.$wait.start('loadingTechnographicData')
      return this.fetchTechnographicVendorsDetails({
        fiId: this.selectedInstitution.id
      }).then(() => {
        this.$wait.end('loadingTechnographicData')
      })
    },
    bdiDataChanged() {
      this.setTechnographicDetails([])
      this.loadTechnographicVendorsData()
    },
    configChanged() {
      this.loadTechnographicVendorsData()
    }
  },
  watch: {}
}
</script>

<style lang="scss" scoped>
.technographic-card {
  background-color: #20a8d8;
  color: white;

  &.pdf-export {
    background-color: #fff;
    color: #0a80aa;

    &.std-border {
      border-color: #0a80aa;
    }
  }
}
</style>
