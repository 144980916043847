<template>
  <div>
    <b-card
      no-body
      :class="[
        { 'pdf-export': renderedForPDF },
        'overflow-hidden std-border theme-card p-3 mb-0 my-2'
      ]"
    >
      <b-row>
        <b-col sm="12">
          <div class="row no-gutters">
            <div class="col-10">
              <h5 class="mb-0">{{ themeName }}</h5>
              <p class="mb-2">{{ metricDetail.performance.theme_name }}</p>
            </div>
            <div class="col-2 text-right">
              <i
                class="icon-info icons small-line-icon font-weight-bold cursor-pointer"
                @click="$refs[`theme-info-${metricDetail.section}`].show()"
              ></i>
            </div>
          </div>
          <b-modal
            size="lg"
            :ref="`theme-info-${metricDetail.section}`"
            class="info-modal"
            title="Primary Theme"
            ok-only
            v-if="!renderedForPDF"
          >
            <ThemeInfo :isCreditUnion="isCreditUnionSelected"></ThemeInfo>
          </b-modal>
          <div class="row no-gutters">
            <div class="col-sm-12">
              <div class="chart-container">
                <Highcharts
                  :options="chartOptions"
                  ref="themeChart"
                ></Highcharts>
                <div class="overlayed"></div>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { Chart } from 'highcharts-vue'
import ThemeInfo from '../ThemeInfo'

export default {
  name: 'ThemeCard',
  props: {
    metricDetail: {
      type: Object,
      required: true,
      default() {
        return {}
      }
    },
    section: {
      type: String,
      required: true
    },
    renderedForPDF: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  components: {
    Highcharts: Chart,
    ThemeInfo: ThemeInfo
  },
  data() {
    return {}
  },
  mounted() {
    if (this.renderedForPDF && window.matchMedia) {
      let chart = this.$refs.themeChart.chart
      let mediaQueryList = window.matchMedia('print')

      mediaQueryList.addListener(() => {
        chart.reflow()
      })
    }
  },
  computed: {
    ...mapGetters('Profile', ['selectedInstitution', 'isCreditUnionSelected']),
    themeName() {
      let name = {
        1: 'Profit',
        2: 'Growth',
        3: 'Risk'
      }[this.metricDetail.card.product_id]
      return 'Primary ' + name + ' Theme'
    },
    exception() {
      return Math.round(this.metricDetail.performance.perf_exception * 100)
    },
    chartOptions() {
      return {
        credits: {
          enabled: false
        },
        chart: {
          backgroundColor: this.renderedForPDF ? '#FFF' : '#20a8d8',
          height: 200
        },
        exporting: {
          enabled: false
        },
        title: {
          text: `<div class="text-center theme-info">
          <h6 class="mb-0">Performance</h6>
          <h4 class="percent mb-0 py-1">${this.exception}%</h4>
          <h6 class="mb-0">Exception</h6>
          </div>`,
          align: 'center',
          verticalAlign: 'middle',
          useHTML: true
        },
        tooltip: {
          enabled: false
        },
        plotOptions: {
          pie: {
            dataLabels: {
              enabled: false
            },
            shadow: false,
            center: ['50%', '50%'],
            size: '115%'
          },
          series: {
            states: {
              hover: {
                enabled: false
              }
            }
          }
        },
        series: [
          {
            type: 'pie',
            name: 'Strength',
            innerSize: '66%',
            data: [
              {
                name: 'exception',
                y: this.exception,
                color: '#0A80AA'
              },
              {
                name: 'performance',
                y: 100 - this.exception,
                color: '#EDECED'
              }
            ]
          }
        ]
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.theme-card {
  background-color: #20a8d8;
  color: white;
  height: 18rem;
  cursor: all-scroll;

  &.pdf-export {
    background-color: #fff;
    color: #0a80aa;

    &.std-border {
      border-color: #0a80aa;
    }
  }
}

.chart-container {
  width: 100%;
  height: 230px;
  position: relative;

  div.overlayed {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}
</style>

<style lang="scss">
.theme-card {
  .theme-info {
    color: white;
  }
  &.pdf-export {
    .theme-info {
      color: #0a80aa;
    }
  }
}
</style>
