<template>
  <div v-if="strategyDetails && strategyDetails.$loading">
    <content-placeholders :rounded="true">
      <content-placeholders-text :lines="10"></content-placeholders-text>
    </content-placeholders>
  </div>
  <div v-else>
    <b-row>
      <b-col sm="4" v-for="(card, index) in strategyCards" :key="index">
        <StrategyCard
          :cardDetail="card.focus_data"
          :renderedForPDF="renderedForPDF"
        ></StrategyCard>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import StrategyCard from './cards/StrategyCard'
import { createResource } from 'vue-async-manager'

export default {
  name: 'ProfileStrategy',
  props: {
    sectionInfo: {
      type: Object,
      required: true,
      default() {
        return {}
      }
    },
    renderedForPDF: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  created() {},
  components: {
    StrategyCard
  },
  data() {
    return {
      sectionName: 'Strategy',
      strategyDetails: null
    }
  },
  computed: {
    ...mapState('Profile', {
      selectedInstitution: state => state.selectedInstitution,
      strategyCards: state => state.strategyCards,
      sectionConfigs: state => state.sectionConfigs
    })
  },
  methods: {
    ...mapActions('Profile', ['fetchStrategyDetails']),
    loadStrategyData() {
      let options = {
        fiId: this.selectedInstitution.id,
        sectionId: this.sectionInfo.id
      }

      this.strategyDetails = createResource(() =>
        this.fetchStrategyDetails(options)
      )
      return this.strategyDetails.read()
    },
    configChanged() {
      this.loadStrategyData()
    }
  },
  watch: {}
}
</script>
