<template>
  <div class="row pt-4">
    <b-col sm="12">
      <b-card
        :class="[{ 'pdf-export': renderedForPDF }, 'std-border mb-0']"
        body-class="p-1"
      >
        <div slot="header">
          <div class="row">
            <div :class="[isMobile ? 'col-12' : 'col-5']">
              <h6 class="mb-0 d-inline-block">Market Share</h6>
              <i
                class="icon-info icons small-line-icon font-weight-bold cursor-pointer"
                @click="$refs[`info-marketShare`].show()"
              ></i>
            </div>
            <div class="col-1 text-left" v-if="!isMobile"></div>
            <div :class="[isMobile ? 'col-12 text-right pr-0' : 'col-6']">
              <h6 class="text-right mb-0">as of {{ currentPeriod }}</h6>
            </div>
            <b-modal
              size="lg"
              ref="info-marketShare"
              class="info-modal"
              title="Market Share"
              ok-only
            >
              <div>
                <p>
                  Deposit Market Share for all financial institutions in the
                  subject’s aggregated county markets. The subject’s footprint
                  consists of the counties in which it reports office locations.
                  Deposits are aggregated from the branches in the county market
                  area. Banks file deposits annually (June) at the branch level
                  while Credit Union branch deposits are calculated by dividing
                  total CU deposits evenly across all of their branches.
                </p>
              </div>
            </b-modal>
          </div>
        </div>
        <v-wait for="fetchMarketData">
          <template slot="waiting">
            <content-placeholders :rounded="true">
              <content-placeholders-text
                :lines="12"
              ></content-placeholders-text>
            </content-placeholders>
          </template>
          <div class="row">
            <div
              :class="[
                renderedForPDF ? 'align-self-start pt-4' : 'align-self-center',
                'col-sm-3'
              ]"
            >
              <Highcharts
                :options="chartOptions"
                ref="marketShareChart"
              ></Highcharts>
            </div>
            <div class="col-sm-9">
              <div class="row pt-1 pb-2">
                <div
                  :class="[isMobile ? 'col-1' : 'col-6']"
                  v-if="!isMobile"
                ></div>
                <div
                  :class="[
                    isMobile ? 'col-4' : 'col-2 text-right  pr-0 ',
                    'font-weight-bold align-self-center'
                  ]"
                >
                  FI Type:
                </div>
                <div :class="[isMobile ? 'col-6' : 'col-4 pr-4']">
                  <b-form-select
                    v-model="marketShareFIType"
                    :options="fiTypes"
                    :select-size="isMobile ? 2 : 1"
                    @input="fetchCompetitors()"
                  ></b-form-select>
                </div>
              </div>
              <div class="deposit-share-table">
                <b-table
                  :items="marketCompetitors"
                  :fields="fields"
                  class="std-border"
                  responsive
                  stacked="xs"
                  show-empty
                  striped
                  small
                >
                  <template v-slot:cell(fi_name)="data">
                    <div class="text-ellipsis">
                      {{ data.item.fi_name }}, {{ data.item.fi_city }},
                      {{ data.item.fi_state }}
                    </div>
                  </template>
                  <template v-slot:cell(branch_share)="data">
                    <div class="text-right">
                      {{ data.item.branch_share | formatValue('P2') }}
                    </div>
                  </template>
                  <template v-slot:cell(deposits)="data">
                    <div class="text-right">
                      {{ data.item.deposits | formatValue('$#,###') }}
                    </div>
                  </template>
                  <template v-slot:cell(share)="data">
                    <div class="text-right">
                      {{ data.item.share | formatValue('P2') }}
                    </div>
                  </template>
                  <template v-slot:cell(growth)="data">
                    <div class="text-right">
                      {{ data.item.growth | formatValue('P2') }}
                    </div>
                  </template>
                </b-table>
              </div>
            </div>
          </div>
        </v-wait>
      </b-card>
    </b-col>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import { Chart } from 'highcharts-vue'
import { createHelpers } from 'vuex-map-fields'
import * as moment from 'moment'

const { mapFields } = createHelpers({
  getterType: 'Profile/getField',
  mutationType: 'Profile/updateField'
})

export default {
  name: 'DepositMarketShare',
  components: {
    Highcharts: Chart
  },
  props: {
    cardDetail: {
      type: Object,
      required: true,
      default() {
        return {}
      }
    },
    renderedForPDF: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      competitors: null,
      growth: null
    }
  },
  mounted() {
    if (this.renderedForPDF && window.matchMedia) {
      let mediaQueryList = window.matchMedia('print')

      mediaQueryList.addListener(() => {
        let chart = this.$refs.marketShareChart.chart
        chart.reflow()
      })
    }
  },
  computed: {
    ...mapState('Profile', ['isMobile']),
    ...mapGetters('Profile', ['selectedInstitution']),
    ...mapFields(['marketShareFIType']),
    fiTypes() {
      if (this.selectedInstitution.fiType === 'bank') {
        return [
          { value: 'bank', text: 'Bank Only' },
          { value: 'both', text: 'Bank & CU' }
        ]
      } else if (this.selectedInstitution.fiType === 'credit_union') {
        return [
          { value: 'credit_union', text: 'CU only' },
          { value: 'both', text: 'CU & Bank' }
        ]
      } else {
        return []
      }
    },
    fields() {
      return [
        {
          key: 'fi_name',
          label: 'FI Name',
          sortable: false,
          class: 'fi-name text-ellipsis'
        },
        {
          key: 'branches',
          label: 'Branches',
          sortable: false,
          class: 'text-right amount'
        },
        {
          key: 'branch_share',
          label: 'Branch Share',
          sortable: false,
          class: 'text-right percent'
        },
        {
          key: 'deposits',
          label: 'Deposits',
          sortable: false,
          class: 'text-right amount'
        },
        {
          key: 'share',
          label: 'Deposit Share',
          sortable: false,
          class: 'text-right percent'
        },
        {
          key: 'growth',
          label: 'Deposit 1-Yr Growth',
          sortable: false,
          class: 'text-right percent'
        }
      ]
    },
    marketCompetitors() {
      return this.cardDetail.market_data.competitors
    },
    marketGrowth() {
      return this.cardDetail.market_data.growth
    },
    currentPeriod() {
      return moment(this.cardDetail.market_data.current_period).format(
        'MMMM YYYY'
      )
    },
    chartOptions() {
      let marketShare = parseFloat((this.marketGrowth * 100).toFixed(2))

      return {
        credits: {
          enabled: false
        },
        chart: {
          height: '90%'
        },
        exporting: {
          enabled: false
        },
        title: {
          text: `<div class="text-center chart-title">
          <div>DEPOSIT</div>
          <h5 class="percent">${marketShare}%</h5>
          <div>MARKET SHARE</div>
          </div>`,
          align: 'center',
          verticalAlign: 'middle',
          useHTML: true
        },
        tooltip: {
          enabled: false
        },
        plotOptions: {
          pie: {
            dataLabels: {
              enabled: false
            },
            shadow: false,
            center: ['50%', '50%'],
            size: '100%'
          },
          series: {
            states: {
              hover: {
                enabled: false
              }
            }
          }
        },
        series: [
          {
            type: 'pie',
            name: 'Strength',
            innerSize: '66%',
            data: [
              {
                y: marketShare,
                color: '#5AB2EF'
              },
              {
                y: 100 - marketShare,
                color: '#edeced'
              }
            ]
          }
        ]
      }
    }
  },
  methods: {
    ...mapActions('Profile', ['fetchMarketDepositShare']),
    fetchCompetitors() {
      this.$wait.start('fetchMarketData')
      return this.fetchMarketDepositShare({
        fiId: this.selectedInstitution.id,
        fiType: this.marketShareFIType
      }).then(() => {
        this.$wait.end('fetchMarketData')
      })
    }
  },
  watch: {}
}
</script>

<style lang="scss" scoped>
.deposit-share-table {
  height: 20rem;
  overflow-y: auto;
}

.pdf-export {
  .deposit-share-table {
    height: auto;
    overflow-y: auto;
  }
}
</style>

<style lang="scss">
.market-section {
  .chart-title {
    font-size: 0.8rem;
    font-weight: bold;
    color: #6c6d70;

    .percent {
      color: #3396b7;
      font-size: 1.25rem;
      font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
      line-height: 1;
      padding: 0.2rem 0;
      margin-bottom: 0;
    }
  }

  .deposit-share-table {
    table {
      @media (min-width: 992px) {
        table-layout: fixed;
      }

      @media (max-width: 991.98px) {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar;
      }

      .fi-name {
        width: 55%;
      }

      .amount {
        width: 15%;
      }

      .percent {
        width: 15%;
      }
    }
  }
}
</style>
