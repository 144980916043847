var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{class:[
    {
      'pdf-export': _vm.renderedForPDF,
      'small-font-on-print-offering': _vm.renderedForPDF
    },
    'overflow-hidden std-border offering-card p-3 mb-0 my-2'
  ],attrs:{"no-body":"","body-class":"p-1"}},[_c('b-row',[_c('b-col',{attrs:{"cols":12}},[_c('h5',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.offering.main_section))]),_c('p',{staticClass:"mb-2"},[_vm._v(_vm._s(_vm.offering.category_name))])])],1),_c('b-row',[_c('b-col',{attrs:{"cols":1}}),_c('b-col',{staticClass:"pl-0",attrs:{"cols":6}},[_c('p',{staticClass:"mb-2"},[_c('b',[_vm._v("Service")])])]),_c('b-col',{staticClass:"pl-0",attrs:{"cols":4}},[_c('span',{staticClass:"mb-2"},[_c('b',[_vm._v("Peer Group %")])])])],1),_c('b-row',[_c('b-col',{attrs:{"cols":12}},_vm._l((_vm.offering.children),function(category,index){return _c('div',{key:index},[(category.children.length)?_c('b-row',[_c('b-col',{staticClass:"pt-1",attrs:{"cols":12}},[_c('b-row',[_c('b-col',{staticClass:"pr-0",attrs:{"cols":1}}),_c('b-col',{staticClass:" pl-0 pr-0",attrs:{"cols":6}},[_c('b',[_vm._v(" "+_vm._s(category.name)+" ")])]),_c('b-col',{attrs:{"cols":1}}),_c('b-col',{staticClass:"pl-0",attrs:{"cols":4}},[_vm._v(" "+_vm._s(category.peer_group_value)+" ")])],1),_vm._l((category.children),function(subCategory,index){return _c('b-row',{key:index},[_c('b-col',{staticClass:" pr-0",attrs:{"cols":1}},[_c('div',{class:[
                    _vm.isMobile
                      ? subCategory.has_service
                        ? 'has-service-mob'
                        : 'has-no-service-mob'
                      : subCategory.has_service
                      ? 'has-service'
                      : 'has-no-service'
                  ]})]),_c('b-col',{staticClass:" pl-0 pr-1",attrs:{"cols":6}},[_c('div',{staticClass:"service-name",attrs:{"title":subCategory.name}},[_vm._v(" "+_vm._s(subCategory.name)+" ")])]),_c('b-col',{staticClass:"align-self-center cursor-pointer pl-0",attrs:{"cols":3,"title":((subCategory.peer_group_value) + "%")}},[_c('b-progress',{attrs:{"value":subCategory.peer_group_value,"max":100}})],1),_c('b-col',{staticClass:" pl-0 pr-2 text-right",attrs:{"cols":2}},[_c('div',{staticClass:"service-name"},[_vm._v(" "+_vm._s(subCategory.peer_group_value + '%')+" ")])])],1)})],2)],1):_c('b-row',[_c('b-col',{staticClass:" pr-0",attrs:{"cols":1}},[_c('div',{class:[
                _vm.isMobile
                  ? category.has_service
                    ? 'has-service-mob'
                    : 'has-no-service-mob'
                  : category.has_service
                  ? 'has-service'
                  : 'has-no-service'
              ]})]),_c('b-col',{staticClass:" pl-0 pr-1",attrs:{"cols":6}},[_c('div',{staticClass:"service-name",attrs:{"title":category.name}},[_vm._v(" "+_vm._s(category.name)+" ")])]),_c('b-col',{staticClass:"align-self-center cursor-pointer pl-0",attrs:{"cols":3,"title":((category.peer_group_value) + "%")}},[_c('b-progress',{attrs:{"value":category.peer_group_value,"max":100}})],1),_c('b-col',{staticClass:" pl-0 pr-2 text-right",attrs:{"cols":2}},[_c('div',{staticClass:"service-name"},[_vm._v(" "+_vm._s(category.peer_group_value + '%')+" ")])])],1)],1)}),0)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }