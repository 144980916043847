<template>
  <v-wait for="fetchProfileDetails">
    <template slot="waiting">
      <content-placeholders :rounded="true">
        <content-placeholders-text :lines="7"></content-placeholders-text>
      </content-placeholders>
    </template>
    <div class="row">
      <div class="col-12">
        <div class="row">
          <div :class="[isMobile ? 'col-12' : 'col-6']">
            <div class="row">
              <div class="col-12">
                <div class="row">
                  <div class="col-12">
                    <h6 class="mb-2"><b>Segmentation</b></h6>
                  </div>
                </div>
                <div :class="[isMobile ? 'row pb-2' : 'row']">
                  <div
                    :class="[isMobile ? 'col-7' : 'col-5', 'd-inline-block']"
                  >
                    Vendor Diversity<sup>
                      <i
                        class="icon-info icons font-weight-bold cursor-pointer"
                        @click="$refs[`info-vendorDiversity`].show()"
                      ></i
                    ></sup>
                    :
                  </div>
                  <div
                    :class="[isMobile ? 'col-5' : 'col-7', 'align-self-end']"
                  >
                    {{ profileDetail.vendor_diversity }}
                  </div>
                  <b-modal
                    size="lg"
                    ref="info-vendorDiversity"
                    class="info-modal"
                    title="Vendor Diversity"
                    ok-only
                  >
                    <div>
                      <p>
                        Vendor Diversity is a purchase-behavior segmentation
                        scheme classifying financial institutions by the
                        proportion of a basket of technology solutions that are
                        purchased from their core provider. Technology solutions
                        within that basket include online bill pay, debit card
                        processing, credit card processing, retail online &
                        mobile banking, business online banking, online account
                        opening, website management & hosting, mortgage loan
                        origination system, consumer loan origination system,
                        investment platform and personal financial management.
                        Institutions are segmented as (1) Core Centric (2) Low
                        Diversity (3) Moderate Diversity or (4) Best-of-Breed
                        based on non-core purchase percentage breakpoints of
                        less than 33%, 33% to 66%, 67% to 80% and greater than
                        80% respectively.
                      </p>
                    </div>
                  </b-modal>
                </div>
                <div :class="[isMobile ? 'row pb-2' : 'row']">
                  <div
                    :class="[isMobile ? 'col-7' : 'col-5', 'd-inline-block']"
                  >
                    Retail Services Innovation<sup
                      ><i
                        class="icon-info icons font-weight-bold cursor-pointer"
                        @click="$refs[`info-retailInnovation`].show()"
                      ></i
                    ></sup>
                    :
                  </div>
                  <div
                    :class="[isMobile ? 'col-5' : 'col-7', 'align-self-end']"
                  >
                    {{ profileDetail.innovation_score_retail }}
                  </div>
                  <b-modal
                    size="lg"
                    ref="info-retailInnovation"
                    class="info-modal"
                    title="Retail Services Innovation"
                    ok-only
                  >
                    <div>
                      <p>
                        Retail Services Innovation is a purchase-behavior
                        segmentation scheme classifying financial intuitions by
                        the relative depth of their retail offering versus asset
                        size peers. Institutions are segmented as (1) Very High
                        (2) High (3) Moderate (4) Low or (5) Very Low based
                        percentage breakpoints.
                      </p>
                    </div>
                  </b-modal>
                </div>
                <div :class="[isMobile ? 'row pb-2' : 'row']">
                  <div
                    :class="[isMobile ? 'col-7' : 'col-5', 'd-inline-block']"
                  >
                    Business Services Innovation<sup
                      ><i
                        class="icon-info icons font-weight-bold  cursor-pointer"
                        @click="$refs[`info-businessInnovation`].show()"
                      ></i
                    ></sup>
                    :
                  </div>
                  <div
                    :class="[isMobile ? 'col-5' : 'col-7', 'align-self-end']"
                  >
                    {{ profileDetail.innovation_score_business }}
                  </div>
                  <b-modal
                    size="lg"
                    ref="info-businessInnovation"
                    class="info-modal"
                    title="Business Services Innovation"
                    ok-only
                  >
                    <div>
                      <p>
                        Business Services Innovation is a purchase-behavior
                        segmentation scheme classifying financial intuitions by
                        the relative depth of their business offering versus
                        asset size peers. Institutions are segmented as (1) Very
                        High (2) High (3) Moderate (4) Low or (5) Very Low based
                        percentage breakpoints.
                      </p>
                    </div>
                  </b-modal>
                </div>
                <div :class="[isMobile ? 'row pb-2' : 'row']">
                  <div
                    :class="[isMobile ? 'col-7' : 'col-5', 'd-inline-block']"
                  >
                    Reported Account Growth<sup
                      ><i
                        class="icon-info icons font-weight-bold  cursor-pointer"
                        @click="$refs[`info-accountGrowth`].show()"
                      ></i></sup
                    >:
                  </div>
                  <div
                    :class="[isMobile ? 'col-5' : 'col-7', 'align-self-end']"
                  >
                    {{ profileDetail.acq_acct_growth_5_yr_score }}
                  </div>
                  <b-modal
                    size="lg"
                    ref="info-accountGrowth"
                    class="info-modal"
                    title="Reported Account Growth"
                    ok-only
                  >
                    <div>
                      <p>
                        Reported Account Growth is a measure of a financial
                        institution’s growth in deposit accounts. The
                        segmentation is based on a five-year annualized growth
                        rate in the number of deposit accounts (excluding
                        retirement accounts) less than or equal to $250,000. The
                        growth rate uses the number of accounts “as-reported.”
                        This means that the growth rate could be impacted by
                        acquisitions over the 5-year period. The growth
                        classification (Very High, High, Moderate, Low, Very
                        Low) is based on the national quintiles of account
                        growth.
                      </p>
                    </div>
                  </b-modal>
                </div>
                <div :class="[isMobile ? 'row pb-2' : 'row']">
                  <div
                    :class="[isMobile ? 'col-7' : 'col-5', 'd-inline-block']"
                  >
                    Acquisition Risk<sup
                      ><i
                        class="icon-info icons font-weight-bold  cursor-pointer"
                        @click="$refs[`info-acquisitionRisk`].show()"
                      ></i></sup
                    >:
                  </div>
                  <div
                    :class="[isMobile ? 'col-5' : 'col-7', 'align-self-end']"
                  >
                    {{ profileDetail.merger_risk_score }}
                  </div>
                  <b-modal
                    size="lg"
                    ref="info-acquisitionRisk"
                    class="info-modal"
                    title="Acquisition Risk"
                    ok-only
                  >
                    <div>
                      <p>
                        Acquisition Risk measures how attractive the institution
                        would be to an acquirer based on what an active acquirer
                        would like purchase. The score is an aggregate of market
                        share, market growth, institution growth, and
                        institution profitability. Generally, an acquirer would
                        like to purchase a high market share franchise in a high
                        growth market with good FI growth and profitability.
                      </p>
                    </div>
                  </b-modal>
                </div>
                <div :class="[isMobile ? 'row pb-2' : 'row']">
                  <div
                    :class="[isMobile ? 'col-7' : 'col-5', 'd-inline-block']"
                  >
                    Failure Risk<sup
                      ><i
                        class="icon-info icons font-weight-bold  cursor-pointer"
                        @click="$refs[`info-failureRisk`].show()"
                      ></i
                    ></sup>
                    :
                  </div>
                  <div
                    :class="[isMobile ? 'col-5' : 'col-7', 'align-self-end']"
                  >
                    {{ profileDetail.failure_risk }}
                  </div>
                  <b-modal
                    size="lg"
                    ref="info-failureRisk"
                    class="info-modal"
                    title="Failure Risk"
                    ok-only
                  >
                    <div>
                      <p>
                        Failure Risk is an aggregate enterprise risk score for
                        the institution based on four (4) contributing component
                        scores: Asset Quality (25%), Capital Adequacy (25%),
                        Earnings Quality (25%) and Liquidity Risk (25%). Each of
                        the four components is in turn driven by contributing
                        component metrics. Institutions are segmented as (1)
                        Very High (2) High (3) Moderate (4) Low or (5) Very Low.
                      </p>
                    </div>
                  </b-modal>
                </div>
                <div :class="[isMobile ? 'row pb-2' : 'row']">
                  <div
                    :class="[isMobile ? 'col-7' : 'col-5', 'd-inline-block']"
                  >
                    Acquisitive Score
                    <sup
                      ><i
                        class="icon-info icons font-weight-bold  cursor-pointer"
                        @click="$refs[`info-acquisitiveScore`].show()"
                      ></i
                    ></sup>
                    :
                  </div>
                  <div
                    :class="[isMobile ? 'col-5' : 'col-7', 'align-self-end']"
                  >
                    {{ profileDetail.acquisitive_score }}
                  </div>
                  <b-modal
                    size="lg"
                    ref="info-acquisitiveScore"
                    class="info-modal"
                    title="Acquisitive Score"
                    ok-only
                  >
                    <div>
                      <p>
                        Acquisitive Score examines the frequency and recency of
                        an institution's acquisitions to score the likelihood
                        that additional acquisitions will be completed in the
                        near future.
                      </p>
                    </div>
                  </b-modal>
                </div>
              </div>
            </div>
          </div>
          <div class="col-6" v-if="!isMobile"></div>
        </div>
      </div>
    </div>
  </v-wait>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: 'SegmentationInsights',
  data() {
    return {
      sectionName: 'Segmentation & Insights'
    }
  },
  props: {
    sectionInfo: {
      type: Object,
      required: true,
      default() {
        return {}
      }
    },
    renderedForPDF: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  created() {},
  computed: {
    ...mapState('Profile', {
      selectedInstitution: state => state.selectedInstitution,
      profileDetail: state => state.profileDetail,
      isMobile: state => state.isMobile
    })
  },
  methods: {
    ...mapActions('Profile', ['fetchBankProfileDetails']),
    fetchProfileDetails() {
      this.$wait.start('fetchProfileDetails')
      return this.fetchBankProfileDetails({
        fiId: this.selectedInstitution.id
      }).then(() => {
        this.$wait.end('fetchProfileDetails')
      })
    },
    configChanged() {
      this.fetchProfileDetails()
    }
  },
  watch: {}
}
</script>

<style lang="scss" scoped>
.site-link {
  font-size: 1rem;
}
</style>
