<template>
  <div class="market-section">
    <v-wait for="loadingMarketData">
      <template slot="waiting">
        <content-placeholders :rounded="true">
          <content-placeholders-text :lines="10"></content-placeholders-text>
        </content-placeholders>
      </template>
      <div>
        <b-row>
          <b-col
            :sm="cardSize(cardDetail)"
            v-for="(cardDetail, index) in validMarketCards"
            :key="index"
          >
            <component
              v-bind:is="componentMapper[cardDetail.card.category]"
              :cardDetail="cardDetail"
              :renderedForPDF="renderedForPDF"
            ></component>
          </b-col>
        </b-row>
      </div>
    </v-wait>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import BranchNetwork from './cards/BranchNetwork'
import MarketCoverage from './cards/MarketCoverage'
import MarketSize from './cards/MarketSize'
import DepositMarketShare from './cards/DepositMarketShare'
import FieldOfMembership from './cards/FieldOfMembership'

export default {
  name: 'Market',
  props: {
    sectionInfo: {
      type: Object,
      required: true,
      default() {
        return {}
      }
    },
    renderedForPDF: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  created() {},
  components: {
    BranchNetwork,
    MarketCoverage,
    MarketSize,
    DepositMarketShare,
    FieldOfMembership
  },
  data() {
    return {
      sectionName: 'Market'
    }
  },
  computed: {
    ...mapState('Profile', {
      selectedInstitution: state => state.selectedInstitution,
      marketCards: state => state.marketCards,
      sectionConfigs: state => state.sectionConfigs
    }),
    componentMapper() {
      return {
        branch_network: 'BranchNetwork',
        market_coverage: 'MarketCoverage',
        market_size: 'MarketSize',
        field_of_membership: 'FieldOfMembership',
        deposit_market_share: 'DepositMarketShare'
      }
    },
    validMarketCards() {
      return this.marketCards.filter(card => {
        if (card.card.category === 'branch_network') {
          return !this.renderedForPDF && card.market_data.values.length
        } else if (card.card.category === 'market_coverage') {
          return card.market_data.coverage
        } else {
          return card.market_data.value || card.market_data.competitors
        }
      })
    }
  },
  methods: {
    ...mapActions('Profile', ['fetchMarketDetails']),
    loadMarketData() {
      let options = {
        fiId: this.selectedInstitution.id,
        sectionId: this.sectionInfo.id
      }
      this.$wait.start('loadingMarketData')

      return this.fetchMarketDetails(options).then(() => {
        this.$wait.end('loadingMarketData')
      })
    },
    configChanged() {
      this.loadMarketData()
    },
    cardSize(cardDetail) {
      return ['branch_network', 'deposit_market_share'].includes(
        cardDetail.card.category
      )
        ? 12
        : 4
    }
  },
  watch: {}
}
</script>

<style lang="scss" src="../scss/profile.scss"></style>

<style lang="scss" scoped>
/deep/ .market-card-header {
  color: white;
  background-color: #1e98c3;
}
/deep/ .market-card {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 11rem;
  color: white;
  background-color: #20a8d8;
}
</style>
