<template>
  <div>
    <div class="fin-profile chart-container">
      <Highcharts :options="chartOptions" ref="splineChart"></Highcharts>
    </div>
  </div>
</template>

<script>
import { Chart } from 'highcharts-vue'
import Vue from 'vue'

export default {
  name: 'SplineChart',
  props: {
    metricDetail: {
      type: Object,
      required: true,
      default() {
        return {}
      }
    },
    showPeers: {
      type: Boolean,
      required: true,
      default: false
    },
    renderedForPDF: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  components: {
    Highcharts: Chart
  },
  data() {
    return {}
  },
  mounted() {
    if (window.matchMedia) {
      let chart = this.$refs.splineChart && this.$refs.splineChart.chart
      let mediaQueryList = window.matchMedia('print')

      mediaQueryList.addListener(() => {
        if (Object.keys(chart).length) {
          chart.reflow()
        }
      })
    }
  },
  computed: {
    seriesData() {
      let bankSeries = {
        name: 'Bank',
        data: this.metricDetail.metric_history.map(metric => {
          let metricVal = Vue.filter('formatMetricNumber')(
            metric.bank,
            this.metricDetail.format
          )
          return [metric.quarter, parseFloat(metricVal)]
        }),
        color: this.renderedForPDF ? '#A7CA77' : '#FFF'
      }

      let peerSeries = {
        name: 'Peer',
        data: this.metricDetail.metric_history.map(metric => {
          let metricVal = Vue.filter('formatMetricNumber')(
            metric.peer,
            this.metricDetail.format
          )
          return [metric.quarter, parseFloat(metricVal)]
        }),
        color: '#0A80AA'
      }
      return this.showPeers ? [bankSeries, peerSeries] : [bankSeries]
    },
    chartOptions() {
      const metricFormat = this.metricDetail.format

      return {
        credits: {
          enabled: false
        },
        chart: {
          type: 'spline',
          backgroundColor: this.renderedForPDF ? '#FFF' : '#20A8D8',
          height: 100
        },
        exporting: {
          enabled: false
        },
        title: {
          text: null
        },
        tooltip: {
          useHTML: true,
          formatter: function() {
            let formattedValue = Vue.filter('formatLabel')(this.y, metricFormat)
            if (this.series.name === 'Bank') {
              return `<div style="text-align: center"><b>${this.key}</b></div><div>FI: ${formattedValue}</div>`
            } else {
              return `<div style="text-align: center"><b>${this.key}</b></div><div>Peer Group: ${formattedValue}</div>`
            }
          }
        },
        legend: {
          enabled: false
        },
        plotOptions: {
          series: {
            animation: false
          },
          spline: {
            marker: {
              enabled: true
            }
          }
        },
        series: this.seriesData,
        xAxis: {
          type: 'category',
          lineColor: 'transparent',
          tickmarkPlacement: 'on',
          tickColor: this.renderedForPDF ? '#0A80AA' : '#FFF',
          labels: {
            style: {
              color: this.renderedForPDF ? '#0A80AA' : '#FFF'
            }
          }
        },
        yAxis: {
          visible: false,
          startOnTick: false,
          endOnTick: false
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.fin-profile {
  &.chart-container {
    cursor: pointer;
  }
}
</style>
