<template>
  <b-card
    no-body
    :header-class="[renderedForPDF ? 'pdf-export' : 'performance-card-header']"
    :class="[
      { 'pdf-export': renderedForPDF },
      'overflow-hidden std-border performance-card  mb-0 my-2'
    ]"
    body-class="p-1"
  >
    <div slot="header">
      <b-row>
        <b-col cols="12">
          <div class="row no-gutters">
            <div class="col-8">
              <h5 class="mb-0">{{ cardDetails[cardDetail.strategy].name }}</h5>
            </div>
            <div class="col-3 text-right">
              <span>{{ showCount }}</span>
            </div>
            <div class="col-1 text-right">
              <i
                class="icon-info icons small-line-icon font-weight-bold cursor-pointer"
                @click="$refs[`info-${cardDetail.strategy}`].show()"
              ></i>
            </div>
          </div>
        </b-col>
        <b-modal
          size="lg"
          :ref="`info-${cardDetail.strategy}`"
          class="info-modal"
          :title="cardDetails[cardDetail.strategy].info_name"
          ok-only
        >
          <div>
            <p>
              {{ cardDetails[cardDetail.strategy].description }}
            </p>
          </div>
        </b-modal>
      </b-row>
    </div>
    <b-row class="pt-3" v-if="showChart">
      <b-col cols="6" class="section-left text-center">
        <b-row no-gutters>
          <b-col cols="12">
            <h5>{{ institutionType }}</h5>
          </b-col>
          <b-col cols="12">
            <h3>{{ bankValue }}{{ showPercentSymbol(bankValue) }}</h3>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="6" class="section-right text-center">
        <b-row no-gutters>
          <b-col cols="12 ">
            <h5>Peers</h5>
          </b-col>
          <b-col cols="12">
            <h3>{{ peersValue }}{{ showPercentSymbol(peersValue) }}</h3>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row v-else>
      <b-col cols="12"><h5 class="text-center">No Data Available</h5> </b-col>
    </b-row>
    <b-row v-if="showChart">
      <b-col cols="12 pt-1">
        <Highcharts :options="chartOptions" ref="performanceChart"></Highcharts>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
// global
import Vue from 'vue'
import { mapGetters } from 'vuex'
import { Chart } from 'highcharts-vue'

export default {
  name: 'PerformanceCard',
  components: {
    Highcharts: Chart
  },
  props: {
    cardDetail: {
      type: Object,
      required: true,
      default() {
        return {}
      }
    },
    renderedForPDF: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      cardDetails: {
        mb_customer_enrollment: {
          name: 'MB Customer Enrollment',
          info_name: 'Enrolled Customers to Deposit Accounts',
          description:
            "Customer Enrollment is measured by dividing the financial institution's estimated current mobile banking app customers(members) by deposit accounts. Deposit accounts as the denominator gives a good proxy for penetration potential particularly for retail mobile banking."
        },

        mb_ratings_to_install: {
          name: 'MB Ratings To Installs',
          info_name: 'Ratings to Installs',
          description:
            'Ratings to Installs is measured by divding the nubmer of mobile banking app ratings by the estimated lifetime mobile banking app installs. This can help measure the level of engagement the end user has with the application. However, differences in ratings volume may be attributable to intra-app ratings prompts.'
        },
        mb_ratings: {
          name: 'MB App Rating',
          info_name: 'App Rating',
          description:
            'App Rating is measured by aggregating the mobile banking app ratings for the Google Play and iTunes app stores for all versions of the app. The ratings are on a five-point scale with five (5) reflecting the highest customer satisfaction. While a useful proxy for customer satisfaction, some financial institutions might have methods of garnering app ratings which tend to result in a higher volume of high ratings, including intra-app ratings prompts.'
        },
        mb_installs_enrollment: {
          name: 'MB Installs Enrollment',
          info_name: 'Installs to Deposit Accounts',
          description:
            "Install enrollment is measured by dividing the financial institution's estimated lifetime mobile banking app installs by deposit accounts. Deposit accounts as the denominator gives a good proxy for penetration potential particularly for retail mobile banking."
        },
        mb_app_age_vendor: {
          name: 'Age with Current MB Vendor',
          info_name: 'Age with Current MB Vendor',
          description:
            'Number of Years FI has been with the current retail mobile banking vendor.'
        },
        olb_customer_enrollment: {
          name: 'OLB Customer Enrollment',
          info_name: 'Enrolled Customers to Deposit Accounts',
          description:
            "Customer Enrollment is measured by dividing the financial institution's estimated current online banking customers(members) by deposit accounts. Deposit accounts as the denominator gives a good proxy for penetration potential particularly for retail online banking."
        }
      }
    }
  },
  mounted() {
    if (this.renderedForPDF && window.matchMedia) {
      let chart = this.$refs.performanceChart
        ? this.$refs.performanceChart.chart
        : null
      let mediaQueryList = window.matchMedia('print')
      mediaQueryList.addListener(() => {
        chart && chart.reflow()
      })
    }
  },
  computed: {
    ...mapGetters('Profile', ['institutionType']),
    showCount() {
      return [
        'mb_ratings',
        'mb_ratings_to_install',
        'mb_app_age_vendor'
      ].includes(this.cardDetail.strategy)
        ? ''
        : this.showChart
        ? Vue.filter('formatValue')(parseInt(this.cardDetail.count), '#,###')
        : ''
    },
    showChart() {
      return this.bankValue !== 'NA' && this.peersValue !== 'NA'
    },
    bankValue() {
      const bankValue = ['mb_app_age_vendor', 'mb_ratings'].includes(
        this.cardDetail.strategy
      )
        ? parseFloat(this.cardDetail.bank_value)
        : parseFloat(this.cardDetail.bank_value) * 100
      return isNaN(bankValue) ? 'NA' : bankValue.toFixed(2)
    },
    peersValue() {
      const peerValue = ['mb_app_age_vendor', 'mb_ratings'].includes(
        this.cardDetail.strategy
      )
        ? parseFloat(this.cardDetail.peer_value)
        : parseFloat(this.cardDetail.peer_value) * 100
      return isNaN(peerValue) ? 'NA' : peerValue.toFixed(2)
    },
    chartOptions() {
      const strategy = this.cardDetail.strategy
      return {
        credits: {
          enabled: false
        },
        chart: {
          type: 'column',
          backgroundColor: this.renderedForPDF ? '#FFF' : '#20A8D8',
          height: 175
          //inverted: true
        },
        exporting: {
          enabled: false
        },
        title: {
          text: null
        },
        legend: {
          enabled: false
        },
        tooltip: {
          enabled: true
          // formatter: function() {
          //   return `${this.key} : ${this.point.y}%`
          // }
        },
        xAxis: {
          lineWidth: 0,
          tickLength: 0,
          labels: {
            enabled: false
          },
          categories: ['Enrollment', 'Enrollment Peers'],
          visible: false
        },
        yAxis: {
          lineWidth: 0,
          gridLineWidth: 0,

          labels: {
            enabled: false,
            autoRotation: 0,
            style: {
              fontWeight: 'bold'
            },
            visible: false,
            formatter: function() {
              return this.value + '%'
            }
          },
          title: {
            text: null
          }
        },

        plotOptions: {
          series: {
            animation: false,
            groupPadding: 0
            //stacking: 'normal'
          }
        },
        series: [
          {
            name: 'Enrollment',
            data: [Number(this.bankValue)],
            color: this.renderedForPDF ? '#A7CA77' : '#FFF',
            dataLabels: {
              enabled: true,
              inside: true,
              align: 'center',
              style: { textOutline: 'none', fontSize: '13px' },
              formatter: function() {
                if (['mb_app_age_vendor', 'mb_ratings'].includes(strategy))
                  return this.point.options.y.toFixed(2)
                else return this.point.options.y + '%'
              }
            }
            //pointPlacement: this.pointPlacementValue()
          },
          {
            name: 'Enrollment Peers',
            data: [Number(this.peersValue)],
            color: '#0A80AA',
            borderColor: '#0A80AA',
            dataLabels: {
              enabled: true,
              inside: true,
              align: 'center',
              style: { textOutline: 'none', fontSize: '13px' },

              formatter: function() {
                if (['mb_app_age_vendor', 'mb_ratings'].includes(strategy))
                  return this.point.options.y.toFixed(2)
                else return this.point.options.y + '%'
              }
            }
            //pointPlacement: this.pointPlacementValue()
          }
        ]
      }
    }
  },
  methods: {
    showPercentSymbol(value) {
      return value === 'NA' ||
        ['mb_app_age_vendor', 'mb_ratings'].includes(this.cardDetail.strategy)
        ? ''
        : '%'
    }
  }
}
</script>

<style lang="scss" scoped>
.performance-card-header {
  color: white;
  background-color: #1e98c3;
}

.performance-card {
  .section-right {
    border-left: solid 2px white;
  }

  .section-left {
    border-right: solid 2px white;
  }

  background-color: #20a8d8;
  color: white;
  //height: 18rem;
  cursor: all-scroll;

  .feature-table {
    width: 100%;
  }
  .custom-legend-holder {
    min-height: 10rem;

    .custom-legend {
      padding: 0.25rem 1rem;
    }

    .custom-legend-label {
      padding: 0.25rem 1rem;
      // display: inline-flex;
      font-weight: bold;
      // font-size: smaller;
    }
  }

  &.pdf-export {
    background-color: #fff;
    color: #0a80aa;

    .section-right,
    .section-left {
      border-color: #0a80aa;
    }
    &.std-border {
      border-color: #0a80aa;
    }

    .custom-legend {
      border-color: #0a80aa;
    }
  }
}
</style>
