<template>
  <div class="contacts-section">
    <div>
      <v-wait for="loadingContactDetails">
        <template slot="waiting">
          <content-placeholders :rounded="true">
            <content-placeholders-text :lines="12"></content-placeholders-text>
          </content-placeholders>
        </template>
        <div class="row pt-1 pb-0 contact-options-card" v-if="!isMobile">
          <div class="col-4">
            <label for="" class="px-2">Has Email:</label>
          </div>
          <div class="col-4 pl-0">
            <label for="" class="px-2">Titles:</label>
          </div>
          <div class="col-4 pl-0">
            <label for="" class="px-2">Roles:</label>
          </div>
        </div>
        <div class="row pt-1 pb-2 contact-options-card" v-else>
          <div class="col-4">
            <label for="" class="px-0 pt-2 pb-2">Has Email:</label>
          </div>
          <div class="col-8 pl-3">
            <multiselect
              v-model="hasEmail"
              :allow-empty="false"
              :options="emailOptions"
              :multiple="false"
              :close-on-select="true"
              :showLabels="false"
              placeholder="Please Select"
              :class="['simple-select']"
              @close="submitFilterSelection"
            >
            </multiselect>
          </div>
        </div>
        <div class="row pt-1 pb-2 contact-options-card" v-if="!isMobile">
          <div class="col-4 pl-3">
            <multiselect
              v-model="hasEmail"
              :allow-empty="false"
              :options="emailOptions"
              :multiple="false"
              :close-on-select="true"
              :showLabels="false"
              placeholder="Please Select"
              :class="['simple-select']"
              @close="submitFilterSelection"
            >
            </multiselect>
          </div>

          <div class="col-4 pl-0">
            <treeselect
              v-model="titles"
              :clearable="false"
              :multiple="true"
              :options="formattedTitles"
              :limit="1"
              @close="submitFilterSelection"
            />
          </div>

          <div class="col-4 pl-0">
            <treeselect
              v-model="roles"
              :clearable="false"
              :multiple="true"
              :options="formattedRoles"
              :limit="1"
              @close="submitFilterSelection"
            />
          </div>
        </div>
        <div class="row pt-1 pb-2 contact-options-card" v-else>
          <div class="col-4">
            <label for="" class="px-0 pt-2 pb-2">Titles:</label>
          </div>
          <div class="col-8 pl-3">
            <treeselect
              v-model="titles"
              :clearable="false"
              :multiple="true"
              :options="formattedTitles"
              :limit="1"
              @close="submitFilterSelection"
            />
          </div>
        </div>
        <div class="row pt-1" v-if="!isMobile">
          <div class="col-4">
            <label for="" class="px-2">Filter:</label>
          </div>
          <div class="col-4 pl-0" v-if="!isMobile">
            <label for="" class="px-0">Download:</label>
          </div>
        </div>
        <div class="row pt-1 pb-2 contact-options-card" v-else>
          <div class="col-4">
            <label for="" class="px-0 pt-2 pb-2">Roles:</label>
          </div>
          <div class="col-8 pl-3">
            <treeselect
              v-model="roles"
              :clearable="false"
              :multiple="true"
              :options="formattedRoles"
              :limit="1"
              @close="submitFilterSelection"
            />
            <!-- @close="submitFilterSelection" -->
          </div>
        </div>
        <div class="row pt-1 pb-2" v-if="!isMobile">
          <div class="col-4 pl-3">
            <b-form-input
              id="filter-input"
              v-model="filter"
              type="search"
              placeholder="Type to Search"
              class="rounded filter-textbox"
            ></b-form-input>
          </div>
          <div class="col-sm-3 pl-0" v-if="!isMobile">
            <multiselect
              v-model="downloadType"
              :options="downloadOptions"
              :multiple="false"
              :allow-empty="false"
              :close-on-select="true"
              :showLabels="false"
              placeholder="Please Select"
              :class="['simple-select']"
            >
            </multiselect>
          </div>

          <div class="col-sm-1 pl-3 download-button" v-if="!isMobile">
            <b-button
              type="button"
              class="btn btn-secondary rounded"
              @click="downloadContacts"
              :disabled="downloadDisabled"
            >
              Download
            </b-button>
          </div>
        </div>
        <div class="row pt-1 pb-2 contact-options-card" v-else>
          <div class="col-4">
            <label for="" class="px-0 pt-2 pb-2">Filter:</label>
          </div>
          <div class="col-8 pl-3">
            <b-form-input
              id="filter-input"
              v-model="filter"
              type="search"
              placeholder="Type to Search"
              class="rounded filter-textbox"
            ></b-form-input>
          </div>
        </div>
        <div class="row">
          <div class="col-12 pl-3">
            <b-table
              :items="contacts"
              :fields="fields"
              :show-empty="true"
              :current-page="currentPage"
              :per-page="perPage"
              :filter="filter"
              @filtered="onFiltered"
              empty-text="No records found."
              responsive="md"
              striped
              hover
            >
              <template v-slot:cell(linked_in)="data">
                <b-link
                  target="_blank"
                  :href="data.item.linked_in"
                  v-b-tooltip="data.item.linked_in"
                  v-if="data.item.linked_in != null"
                  ><i class="fa fa-external-link" aria-hidden="true"></i
                ></b-link>
              </template>
            </b-table>
          </div>
          <div class="row pl-3">
            <div class="col-sm-12 pl-3">
              <b-pagination
                :total-rows="getLength"
                :per-page="perPage"
                v-model="currentPage"
              >
              </b-pagination>
            </div>
          </div>
        </div>
      </v-wait>
    </div>
  </div>
</template>
<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import axios from 'axios'
import Multiselect from 'vue-multiselect'
import Treeselect from '@riophae/vue-treeselect'
import { createHelpers } from 'vuex-map-fields'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import '@/plugins/css/vue-treeselect-overrides.css'
import downloadsAPI from '@/modules/core/api/downloads'
import reportWriterElementsAPI from '@/api/finapps/report_writer_elements'
const { mapFields } = createHelpers({
  getterType: 'Profile/getField',
  mutationType: 'Profile/updateField'
})

export default {
  name: 'FIContacts',
  components: { Multiselect, Treeselect },
  props: {
    sectionInfo: {
      type: Object,
      required: true,
      default() {
        return {}
      }
    },
    renderedForPDF: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  created() {},
  data() {
    return {
      sectionName: 'FI Contacts',
      perPage: 20,
      totalRows: 1,
      filter: null,
      filteredDataLength: 0,
      currentPage: 1,
      emailOptions: ['Yes', 'No', 'Both'],
      downloadOptions: ['csv', 'Excel'],
      downloadType: 'Excel',
      fields: [
        { key: 'first_name', label: 'First Name', sortable: true },
        { key: 'last_name', label: 'Last Name', sortable: true },
        { key: 'title', label: 'Title', sortable: true },
        { key: 'phone', label: 'Phone', sortable: false },
        { key: 'email', label: 'Email', sortable: false },
        { key: 'linked_in', label: 'LinkedIn', sortable: false },
        { key: 'titles', label: 'Titles', sortable: true },
        { key: 'roles', label: 'Roles', sortable: true }
      ]
    }
  },
  computed: {
    ...mapState('Profile', {
      selectedInstitution: state => state.selectedInstitution,
      span: state => state.span,
      currentPeriod: state => state.currentPeriod,
      contacts: state => state.contacts,
      sectionConfigs: state => state.sectionConfigs,
      isMobile: state => state.isMobile
    }),
    ...mapFields(['hasEmail', 'titles', 'roles']),
    ...mapState('ReportWriter', {
      contactTitles: state => state.contactTitles,
      contactRoles: state => state.contactRoles
    }),
    formattedTitles() {
      return this.contactTitles.map(title => this.formatTitle(title))
    },
    formattedRoles() {
      return this.contactRoles.map(role => this.formatRole(role))
    },
    downloadDisabled() {
      return this.filteredDataLength <= 0
    },
    getLength() {
      return this.filteredDataLength
    }
  },
  mounted() {
    this.loadTitlesAndRoles()
  },
  methods: {
    ...mapMutations('ReportWriter', ['setContactTitles', 'setContactRoles']),
    ...mapActions('Profile', [
      'fetchContactDetails',
      'fetchFiteredContactDetails'
    ]),
    formatTitle(title) {
      let formattedTitle = {
        id: title.id,
        label: title.value
      }

      if (title.child && title.child.length) {
        formattedTitle.children = title.child.map(ct => this.formatTitle(ct))
      }
      return formattedTitle
    },
    formatRole(role) {
      let formattedRole = {
        id: role.id,
        label: role.value
      }

      if (role.child && role.child.length) {
        formattedRole.children = role.child.map(cr => this.formatRole(cr))
      }

      return formattedRole
    },
    loadContacts() {
      this.setDefaultTitlesAndRoles()
      let options = {
        fiId: this.selectedInstitution.id,
        sectionId: this.sectionInfo.id,
        hasEmail: this.hasEmail.toLowerCase(),
        titles: this.titles,
        roles: []
      }
      this.$wait.start('loadingContactDetails')

      return this.fetchContactDetails(options).then(() => {
        this.filteredDataLength = this.contacts.length
        this.filter = null
        this.$wait.end('loadingContactDetails')
      })
    },
    configChanged() {
      this.loadContacts()
    },
    loadTitlesAndRoles() {
      if (this.contactTitles.length && this.contactRoles.length) {
        return Promise.resolve()
      }
      this.$wait.start('loadingContactDetails')
      return reportWriterElementsAPI
        .titlesAndRoles()
        .then(res => {
          this.setContactTitles(res.titles)
          this.setContactRoles(res.roles)
          this.setDefaultTitlesAndRoles()
          this.loadFilteredContacts()
        })
        .finally(() => {
          this.$wait.end('loadingContactDetails')
        })
    },
    setDefaultTitlesAndRoles() {
      this.hasEmail = 'Yes'
      if (this.contactTitles.length) {
        this.titles = this.formattedTitles
          .filter(title => title.label === 'Executive Management')
          .map(title => title.id)
      }
      // if (this.contactRoles.length) {
      //   this.roles = this.contactRoles.map(role => role.id)
      // }
    },
    loadFilteredContacts() {
      let options = {
        fiId: this.selectedInstitution.id,
        hasEmail: this.hasEmail.toLowerCase(),
        titles: this.titles,
        roles: this.roles
      }
      this.$wait.start('loadingContactDetails')
      let prevFilter = this.filter
      return this.fetchFiteredContactDetails(options)
        .then(() => {
          this.filteredDataLength = this.contacts.length
          this.filter = null
          this.$wait.end('loadingContactDetails')
        })
        .then(() => {
          this.filter = prevFilter
        })
    },
    submitFilterSelection() {
      this.loadFilteredContacts()
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
      this.filteredDataLength = filteredItems.length
    },
    showToast() {
      this.$toasted.show('Contacts report successfully downloaded!', {
        icon: 'print',
        type: 'success'
      })
    },
    downloadContacts() {
      if (this.downloadType === 'csv') {
        let baseUrl =
          axios.defaults.baseURL === '/'
            ? process.env.VUE_APP_URL_NAME
            : axios.defaults.baseURL
        let titles =
          this.titles.length > 0
            ? '&titles[]=' + this.titles.join('&titles[]=')
            : ''
        let roles =
          this.roles.length > 0
            ? '&roles[]=' + this.roles.join('&roles[]=')
            : ''
        let filter = this.filter ? this.filter : ''
        let url =
          baseUrl +
          '/api/advisors/contacts_download?institution_id=' +
          this.selectedInstitution.id +
          '&has_email=' +
          this.hasEmail.toLowerCase() +
          titles +
          roles +
          '&filter=' +
          filter
        //console.log(url)
        window.open(url, '_blank')
        this.showToast()
      } else {
        let params = {
          fiId: this.selectedInstitution.id,
          hasEmail: this.hasEmail.toLowerCase(),
          downloadType: this.downloadType,
          titles: this.titles,
          roles: this.roles,
          filter: this.filter
        }

        downloadsAPI.contactsDownload(params).then(() => {
          this.showToast()
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.mobile_tree_select {
  overflow-x: auto;
}
.download-button {
  padding-top: 0.12rem;
  padding-bottom: 0.12rem;
}
.filter-textbox {
  height: 100%;
  border: 1px solid #e8e8e8;
}
</style>
