<template>
  <div>
    <b-card
      no-body
      :class="[
        { 'pdf-export': renderedForPDF },
        'overflow-hidden std-border metric-card p-3 mb-0 my-2'
      ]"
    >
      <b-row>
        <b-col sm="12">
          <div class="row no-gutters">
            <div class="col-11">
              <h5 class="mb-0">{{ metricDetail.name }}</h5>
              <p class="metric-sub-title" :title="metricDetail.sec_title">
                {{ metricDetail.sec_title }}
              </p>
            </div>
            <div class="col-1 text-right">
              <i
                class="icon-info icons small-line-icon font-weight-bold cursor-pointer"
                @click="$refs[`info-${metricDetail.configuration_id}`].show()"
              ></i>
            </div>
          </div>
        </b-col>
        <b-modal
          size="lg"
          :ref="`info-${metricDetail.configuration_id}`"
          class="info-modal"
          :title="metricDetail.name"
          ok-only
        >
          <div>
            <p>
              {{ metricDetail.metric_description }}
            </p>
          </div>
        </b-modal>
        <b-col
          :cols="showPeers ? 6 : 12"
          :class="{ 'section-left': showPeers, 'text-center': !showPeers }"
        >
          <b-row no-gutters>
            <b-col sm="12">
              <h5>{{ institutionType }}</h5>
            </b-col>
            <b-col sm="12">
              <h3>
                {{ latestMetric.bank | formatValue(this.metricDetail.format) }}
              </h3>
            </b-col>
            <b-col sm="12">
              <p>
                {{ parseInt(this.metricDetail.national_rank.bank)
                }}<sup>{{ ordinal(this.metricDetail.national_rank.bank) }}</sup>
                Percentile
              </p>
            </b-col>
          </b-row>
        </b-col>
        <b-col :cols="6" class="section-right" v-if="showPeers">
          <b-row no-gutters>
            <b-col sm="12">
              <h5>Peers</h5>
            </b-col>
            <b-col sm="12">
              <h3>
                {{ latestMetric.peer | formatValue(this.metricDetail.format) }}
              </h3>
            </b-col>
            <b-col sm="12">
              <p>
                {{ parseInt(this.metricDetail.national_rank.peer)
                }}<sup>{{ ordinal(this.metricDetail.national_rank.peer) }}</sup>
                Percentile
              </p>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row v-if="showGraph">
        <b-col sm="12">
          <SplineChart
            :metricDetail="metricDetail"
            :showPeers="showPeers"
            :renderedForPDF="renderedForPDF"
          ></SplineChart>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
// global
import { mapGetters } from 'vuex'
// ui components
import SplineChart from '../SplineChart'
// utils
import { numberOrdinal } from '@/utilities.js'

export default {
  name: 'MetricCard',
  components: {
    SplineChart
  },
  props: {
    metricDetail: {
      type: Object,
      required: true,
      default() {
        return {}
      }
    },
    section: {
      type: String,
      required: true
    },
    showPeers: {
      type: Boolean,
      required: true,
      default: false
    },
    showGraph: {
      type: Boolean,
      required: true,
      default: false
    },
    renderedForPDF: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters('Profile', ['institutionType']),
    latestMetric() {
      let [metric] = this.metricDetail.metric_history.slice(-1)
      return metric
    }
  },
  methods: {
    ordinal(number) {
      return numberOrdinal(number)
    }
  }
}
</script>

<style lang="scss" scoped>
.metric-card {
  height: 18rem;
  background-color: #20a8d8;
  color: white;
  cursor: all-scroll;

  .section-right {
    border-left: solid 2px white;
  }

  .section-left {
    border-right: solid 2px white;
  }

  .metric-sub-title {
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
    white-space: nowrap;
  }

  &.pdf-export {
    background-color: #fff;
    color: #0a80aa;

    .section-right,
    .section-left {
      border-color: #0a80aa;
    }

    &.std-border {
      border-color: #0a80aa;
    }
  }
}
</style>
