<template>
  <div class="digital-channels-section">
    <v-wait for="loadingDigitalChannelsData">
      <template slot="waiting">
        <content-placeholders :rounded="true">
          <content-placeholders-text :lines="20"></content-placeholders-text>
        </content-placeholders>
      </template>
      <div
        v-if="digitalChannelsCards.length"
        :class="{ processing: updatingCardPositions }"
      >
        <div class="row mb-2">
          <div class="col-sm-8">
            <h5>Insight into Retail Mobile &amp; Online Banking Performance</h5>
          </div>
          <div class="col-sm-4">
            <div class="row">
              <div class="col-sm-12">
                <b>Current Period:</b> {{ currentPeriod }}
              </div>
              <div class="col-sm-12"><b>Peer Group:</b> {{ peerGroup }}</div>
            </div>
          </div>
        </div>
        <div class="row justify-content-space-evenly">
          <!-- <div class="col-sm-2 p-0"></div> -->
          <div
            v-for="(colCards, index) in colGroupedCards"
            :key="index"
            class="col-sm-4 p-0"
          >
            <draggable
              :class="[{ repositioning: repositioning }, 'mx-2']"
              :list="colCards"
              group="col1"
              @change="saveCardPosition"
              @start="dragStart"
              @end="dragEnd"
            >
              <div v-for="(performanceDetails, index) in colCards" :key="index">
                <div class="col-sm-12 px-1">
                  <component
                    v-bind:is="cardTypes[performanceDetails.card.category]"
                    :cardDetail="performanceDetails.performance_data"
                    :showGraph="canShowGraph"
                    :renderedForPDF="renderedForPDF"
                    section="DigitalChannels"
                  ></component>
                </div>
              </div>
            </draggable>
          </div>

          <!-- </div>
        <div class="row">

          <div
            v-for="(colCards, index) in colGroupedCards"
            :key="index"
            class="col-sm-6 p-0"
          >
            <draggable
              :class="[{ repositioning: repositioning }, 'mx-2']"
              :list="colCards"
              group="col1"
              @change="saveCardPosition"
              @start="dragStart"
              @end="dragEnd"
            >
              <div>
                <div class="col-sm-12 px-1">
                  <component
                    v-bind:is="cardTypes['feature']"
                    :cardDetail="featureDetail(1)"
                    :showGraph="canShowGraph"
                    :renderedForPDF="false"
                    section="DigitalChannels"
                  ></component>
                </div>
              </div>
            </draggable>
          </div>-->
        </div>
      </div>
    </v-wait>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import PerformanceCard from './cards/PerformanceCard'
//import FeaturesCard from './cards/FeaturesCard'
import draggable from 'vuedraggable'
import _ from 'lodash'
import userProfileAPI from '../api/user.profile'

export default {
  name: 'DigitalChannels',
  components: {
    PerformanceCard,
    //FeaturesCard,
    draggable
  },
  props: {
    sectionInfo: {
      type: Object,
      required: true,
      default() {
        return {}
      }
    },
    renderedForPDF: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  created() {},
  data() {
    return {
      sectionName: 'Digital Channels',
      colGroupedCards: [],
      updatingCardPositions: false,
      repositioning: false
    }
  },
  computed: {
    ...mapState('Profile', {
      selectedInstitution: state => state.selectedInstitution,
      span: state => state.span,
      currentPeriod: state => state.currentPeriod,
      digitalChannelsCards: state => state.digitalChannelsCards,
      sectionConfigs: state => state.sectionConfigs
    }),
    peerGroup() {
      return this.digitalChannelsCards.length > 0
        ? this.digitalChannelsCards[0].performance_data.grouping
        : ''
    },

    metricConfigs() {
      return this.sectionConfigs['Digital Channels']
    },
    cardTypes() {
      return {
        performance: 'PerformanceCard'
        //feature: 'FeaturesCard'
      }
    },
    canShowGraph() {
      let config = this.metricConfigs.others.find(
        config => config.category === 'graph'
      )
      return config && config.selected_value === 'Yes'
    }
  },
  methods: {
    ...mapActions('Profile', ['fetchDigitalChannelsDetails']),

    canShowPeers() {
      let peerConfig = this.metricConfigs.global.find(
        config => config.category === 'show_peer'
      )
      return peerConfig && peerConfig.selected_value === 'Yes'
    },
    loadDigitalChannelsData() {
      let options = {
        fiId: this.selectedInstitution.id,
        sectionId: this.sectionInfo.id
      }
      this.$wait.start('loadingDigitalChannelsData')

      return this.fetchDigitalChannelsDetails(options).then(() => {
        this.$wait.end('loadingDigitalChannelsData')
      })
    },
    configChanged() {
      this.loadDigitalChannelsData()
    },
    dragStart() {
      this.repositioning = true
    },
    dragEnd() {
      this.repositioning = false
    },
    saveCardPosition(type) {
      if (type.added || type.moved) {
        this.calculateCardPosition()

        let cardsInfo = this.colGroupedCards.reduce((cardData, colCards) => {
          return cardData.concat(
            colCards.map(colCard => this.filterApiAttrs(colCard))
          )
        }, [])

        this.updatingCardPositions = true
        userProfileAPI
          .updateCardPosition({
            section_id: this.sectionInfo.id,
            cards: cardsInfo
          })
          .then(() => {
            this.$toasted.show('Card order updated successfully.', {
              icon: 'user-circle',
              type: 'success'
            })
            this.updatingCardPositions = false
          })
      }
    },
    calculateCardPosition() {
      this.colGroupedCards.forEach((colCards, colIndex) => {
        colCards.forEach((card, rowIndex) => {
          card.card.card_column = colIndex + 1
          card.card.card_row = rowIndex + 1
        })
      })
    },
    filterApiAttrs(colCard) {
      return {
        card_id: colCard.card.id,
        column: colCard.card.card_column,
        row: colCard.card.card_row,
        configuration_id: colCard.configuration_id
      }
    }
  },
  watch: {
    digitalChannelsCards: {
      handler: function() {
        let groupedCards = _.mapValues(
          _.groupBy(this.digitalChannelsCards, 'card.card_column')
        )
        this.colGroupedCards = [
          (groupedCards[1] || []).sort((c1, c2) =>
            c1.card.card_row > c2.card.card_row ? 1 : -1
          ),
          (groupedCards[2] || []).sort((c1, c2) =>
            c1.card.card_row > c2.card.card_row ? 1 : -1
          )
          // (groupedCards[3] || []).sort((c1, c2) =>
          //   c1.card.card_row > c2.card.card_row ? 1 : -1
          // )
        ]
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped>
.digital-channels-section {
  margin-top: -0.75rem;
  .justify-content-space-evenly {
    justify-content: space-evenly;
  }
}
</style>
